import { createContext } from 'react';

export type ClickedConversationInfo = {
    conversationId: string;
    clickedEventId: string;
};

export type ConversationContextType = {
    conversationId: string;
    isGroup: boolean;
    clickedConversation: ClickedConversationInfo;
    setCurrentConversationId: (conversationId: string) => void;
    setIsGroup: (isGroup: boolean) => void;
    setClickedConversation: (clickedConversation: ClickedConversationInfo) => void;
};

export const ConversationContext = createContext<ConversationContextType>({
    conversationId: '',
    isGroup: false,
    clickedConversation: { conversationId: '', clickedEventId: '' },
    setCurrentConversationId: () => {},
    setIsGroup: () => {},
    setClickedConversation: () => {},
});
