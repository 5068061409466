import { createConversationsDocumentsApi, EchoAuth } from 'idside-core';
import Keycloak from 'keycloak-js';
import { Document } from '../component/Message/Message';

export const downloadFiles = async (
    documents: Document[],
    currentOrganisationId: string,
    currentUserId: string,
    authInstance: Keycloak | EchoAuth,
) => {
    try {
        await Promise.all(
            documents.map(async (singleDocument: Document) => {
                if (!singleDocument.data && singleDocument.id) {
                    const documentsApi = await createConversationsDocumentsApi(authInstance!);
                    const response = await documentsApi.getDocumentById(
                        currentOrganisationId,
                        singleDocument.copyInitiator ?? currentUserId,
                        singleDocument.id,
                    );

                    singleDocument.data = response.data.data;
                }
            }),
        );

        return documents;
    } catch (err: any) {
        throw new Error('Error obtaining document');
    }
};

export const downloadFile = async (
    document: Document,
    currentOrganisationId: string,
    currentUserId: string,
    authInstance: Keycloak | EchoAuth,
) => {
    try {
        if (!document.data) {
            if (document.id) {
                const documentsApi = await createConversationsDocumentsApi(authInstance!);
                const documentResponse = await documentsApi.getDocumentById(
                    currentOrganisationId,
                    document.copyInitiator ?? currentUserId,
                    document.id
                );

                document.data = documentResponse.data.data
            } else {
                throw new Error('Error obtaining document');
            }
        }
        
        return document;
    } catch (err: any) {
        throw new Error('Error obtaining document');      
    }
};

export const loadDocumentDataIfNeeded = async (
    documents: Document[], 
    index: number, 
    currentOrganisationId: string,
    currentUserId: string,
    authInstance: Keycloak | EchoAuth
): Promise<Document[]> => {
    if (documents && index < documents.length) {
        let document: Document = documents[index];
        let documentImageWithData: Document | undefined;

        if (document) {
            documentImageWithData = await downloadFile(document, currentOrganisationId, currentUserId, authInstance);
            if (documentImageWithData) {
                document = documentImageWithData;
                return documents;
            } 
        }
    }

    throw new Error('Document is invalid or not found')        
}

