/* eslint-disable max-lines-per-function */
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CreateGroupInput from '../../component/CreateGroupInput/CreateGroupInput';
import {
    InterlocutorType,
    RecipientSummaryListUI,
    SearchUsageType,
    SearchUIContainer,
    getAvatarImagePath,
} from 'idside-ui';
import { SvgIcon } from '@progress/kendo-react-common';
import { plusCircleIcon } from '@progress/kendo-svg-icons';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { useNavigate, useLocation } from 'react-router-dom';
import NotificationMessage from '../../component/NotificationMessage/NotificationMessage';
import { AxiosResponse } from 'axios';

import LoadingSpinner from '../../component/LoadingSpinner/LoadingSpinner';
import BackButton from '../../component/BackButton/BackButton';
import './group.css';
import { ConversationContext } from '../../Contexts/conversationIdContext';
import {
    createConversationsApi,
    createUsersApi,
    buildLogsAndPostToIndexedDB,
    settings,
    AuthService,
    useAuth,
} from 'idside-core';
import UseUserAttributes from '../../hooks/useUserAttributes';

interface IDataItem {
    [groupname: string]: any;
}

export default function Group() {
    const [t] = useTranslation(['common', 'validation']);
    const {
        orgID: currentOrganisationId,
        currentUserId,
        setError,
        setErrorContent,
        setIsConversationActive,
    } = useContext(GlobalInfoContext);
    const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
    const [alertMemberAlreadyInGroup, setAlertMemberAlreadyInGroup] = useState<boolean>(false);
    const [alertQuitGroup, setAlertQuitGroup] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [isReadyToDisplay, setIsReadyToDisplay] = useState<boolean>(true);
    const [newMemberAdded, setNewMemberAdded] = useState<boolean>(false);
    const [groupName, setGroupName] = useState('');
    const [recipientsAdded, setRecipientsAdded] = useState<Array<{ id: string }>>([]);
    const { setCurrentConversationId: setConversationIDContext } = useContext(ConversationContext);
    const [recipientsGroup, setRecipientsGroup] = useState<Array<any>>([]);
    const navigate = useNavigate();
    const auth = useAuth();
    const { authInstance } = auth;
    const MAX_CHARACTERS = 255;

    const handleOpenSearch = (e: any) => {
        e.preventDefault();
        setVisibleSearch(true);
    };

    let data: any = useLocation();
    useEffect(() => {
        const fetchConversations = async () => {
            const startFetchConv = new Date().getTime();
            if (AuthService.getToken(authInstance!)) {
                try {
                    const conversationsApi = await createConversationsApi(authInstance!, currentOrganisationId);
                    const { data: getConversationData }: AxiosResponse = await conversationsApi.getConversationById(data.state.idConversationGroup);
                    setGroupName(getConversationData.name);
                    getConversationData.recipients.forEach((result: any) => {
                        loadOrAddMemberToGroup(result.id, false);
                    });
                    setIsEditing(true);
                    setIsReadyToDisplay(true);
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - startFetchConv,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        currentUserId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    console.log(err);
                }
            }
        };
        if (data.state.idConversationGroup) {
            setIsReadyToDisplay(false);
            fetchConversations();
        }
    }, [data.state.idConversationGroup]);

    const loadOrAddMemberToGroup = async (userId: string, addNew = true, interlocuteurType?: InterlocutorType) => {
        const userFound: any = recipientsGroup.find((user: any) => {
            return user.id === userId;
        });
        if (userFound !== undefined) {
            setAlertMemberAlreadyInGroup(true);
        } else {
            if (currentUserId !== userId) {
                if (AuthService.getToken(authInstance!)) {
                    const startGetUserSummary = new Date().getTime();
                    try {
                        const usersApi = await createUsersApi(authInstance!, currentOrganisationId);
                        const result = await usersApi.getUserSummaryById(
                            userId,
                            currentOrganisationId,
                        );
                        if (result.data) {
                            setRecipientsAdded((prevState) => [...prevState, { id: result.data.id! }]);
                            setRecipientsGroup((prevState) => [...prevState, result.data]);
                            addNew && setNewMemberAdded(true);
                        }
                    } catch (err: any) {
                        await buildLogsAndPostToIndexedDB(
                            err.message,
                            err.stack,
                            new Date().getTime() - startGetUserSummary,
                            settings.appId,
                            window.location.origin,
                            err.config.headers.trackingId,
                            err.config[`axios-retry`].retries,
                            authInstance?.sessionId!,
                            currentUserId,
                            err.config.headers.deviceId,
                            err.config.headers.organisationId,
                            err.config.headers.provider,
                            settings.dbName,
                            settings.storeIdTexto,
                        );
                        console.log('Error loadOrAddMemberToGroup', err, userId);
                        setError(true);
                        setErrorContent(err);
                    } finally {
                        setVisibleSearch(false);
                        setIsReadyToDisplay(true);
                    }
                }
            } else if (addNew) {
                setAlertMemberAlreadyInGroup(true);
                console.log(`Member ${userId} was not added because he is automatically included in the group`);
            }
        }
    };

    const preventPost = (e: any) => {
        e.keyCode === 13 && e.preventDefault();
    };

    const handleQuitGroup = async () => {
        if (AuthService.getToken(authInstance!)) {
            const startEditConv = new Date().getTime();
            try {
                const conversationsApi = await createConversationsApi(authInstance!, currentOrganisationId);
                await conversationsApi.editConversationV2(
                    data.state.idConversationGroup,
                    {
                        removedRecipients: [{ id: currentUserId }],
                    },
                    {
                        headers: {
                            currentUserId: currentUserId,
                        },
                    },
                );
                setIsConversationActive(-1);
                setConversationIDContext('');
                navigate(`/chat`);
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - startEditConv,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.log(`Error quit group conversation ${data.state.idConversationGroup}`, err);
            }
        }
    };

    const inputValidator = (value: string) => (!value ? t('mustNameGroup', { ns: 'validation' }) : '');

    const handleSubmit = async (dataItem: IDataItem) => {
        if (AuthService.getToken(authInstance!)) {
            if (isEditing) {
                const startHandleSubmit = new Date().getTime();
                try {
                    const conversationsApi = await createConversationsApi(authInstance!, currentOrganisationId);
                    const { data: editConvesationData } = await conversationsApi.editConversationV2(
                        data.state.idConversationGroup,
                        {
                            name: dataItem.groupName,
                            recipients: recipientsAdded,
                        },
                        { headers: { currentUserId: currentUserId } },
                    );
                    navigate(`/chat/${currentOrganisationId}/${editConvesationData.id}`);
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - startHandleSubmit,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        currentUserId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    console.log(`Error editing group conversation ${dataItem.groupName} by ${currentUserId}`, err);
                    setErrorContent(err);
                    setError(true);
                }
            } else {
                const startPostConv = new Date().getTime();
                try {
                    const conversationsApi = await createConversationsApi(authInstance!);
                    const { data: postConversationData } = await conversationsApi.postConversation(
                        currentOrganisationId,
                        {
                            creatorId: currentUserId,
                            name: dataItem.groupName,
                            recipients: [...recipientsAdded, { id: currentUserId }],
                        },
                    );
                    navigate(`/chat/${currentOrganisationId}/${postConversationData.id}`);
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - startPostConv,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        currentUserId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    console.log(
                        `Error creating group conversation ${dataItem.groupName} by author ${currentUserId}`,
                        err,
                    );
                    setErrorContent(err);
                    setError(true);
                }
            }
        }
    };

    return (
        <div className="container-group">
            {alertQuitGroup && (
                <NotificationMessage
                    onConfirmYes={handleQuitGroup}
                    onClose={() => setAlertQuitGroup(false)}
                    title={t('confirmation', { ns: 'validation' })}
                    textContent={t('areYouSureQuitGroup', { groupName: groupName, ns: 'validation' })}
                />
            )}
            {alertMemberAlreadyInGroup && (
                <NotificationMessage
                    onClose={() => setAlertMemberAlreadyInGroup(false)}
                    title={t('memberAlreadyInGroup', { groupName: '', ns: 'validation' })}
                    textContent={t('memberAlreadyInGroup', { groupName: groupName, ns: 'validation' })}
                />
            )}
            {!isReadyToDisplay ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Form
                        initialValues={{
                            groupName: groupName,
                        }}
                        ignoreModified={isEditing && newMemberAdded && true}
                        onSubmit={handleSubmit}
                        render={(formRenderProps) => (
                            <FormElement>
                                <fieldset className={'k-form-fieldset'}>
                                    <Field
                                        id={'groupName'}
                                        name={'groupName'}
                                        label={t('groupName')}
                                        max={MAX_CHARACTERS}
                                        value={formRenderProps.valueGetter('groupName')}
                                        component={CreateGroupInput}
                                        validator={inputValidator}
                                        onKeyDown={preventPost}
                                    />
                                </fieldset>

                                <h1 className="vertical-top-space2 ">{t('groupMember')}</h1>
                                {isEditing &&
                                    (newMemberAdded || formRenderProps.touched) &&
                                    (formRenderProps.allowSubmit = true)}
                                {recipientsGroup.length > 0 ? (
                                    <div>
                                        <RecipientSummaryListUI
                                            filteredList={recipientsGroup}
                                            disableClick={true}
                                            authInstance={authInstance!}
                                            setError={setError}
                                            setErrorContent={setErrorContent}
                                            authService={AuthService}
                                            getAvatarImagePath={getAvatarImagePath}
                                            orgID={currentOrganisationId}
                                            useUserAttributes={UseUserAttributes}
                                            searchUsageType={SearchUsageType.UsageForCreateGroup}
                                            authContext={auth}
                                        />
                                    </div>
                                ) : (
                                    <div className="alert">
                                        {(formRenderProps.allowSubmit = false)} {t('add1member', { ns: 'validation' })}
                                    </div>
                                )}

                                <div className="add-member">
                                    <h1
                                        className="vertical-top-space2 title-add-member"
                                        onClick={handleOpenSearch}
                                        data-testid="add-group-button">
                                        <SvgIcon
                                            className="icon-add-member"
                                            icon={plusCircleIcon}
                                            size="medium"
                                            themeColor={'primary'}
                                        />
                                        {t('addMember')}
                                    </h1>
                                    {visibleSearch && (
                                        <Dialog
                                            title={t('search.title')}
                                            onClose={() => setVisibleSearch(false)}
                                            minWidth={'80%'}
                                            height={'80%'}>
                                            <SearchUIContainer
                                                funcForSelectedUserId={loadOrAddMemberToGroup}
                                                filteredList={[]}
                                                currentOrganisationId={currentOrganisationId}
                                                setError={setError}
                                                setErrorContent={setErrorContent}
                                                currentUserId={currentUserId}
                                                authInstance={authInstance!}
                                                useUserAttributes={UseUserAttributes}
                                                searchUsageType={SearchUsageType.UsageForCreateGroup}
                                                authContext={auth}
                                            />
                                        </Dialog>
                                    )}
                                </div>

                                <fieldset className={'k-form-fieldset'}>
                                    {isEditing && (
                                        <div className="k-form-buttons k-justify-content-between">
                                            <BackButton text={t('button.back')} />
                                            <Button
                                                themeColor={'primary'}
                                                type={'button'}
                                                onClick={() => setAlertQuitGroup(true)}>
                                                {t('button.quitGroup')}
                                            </Button>
                                        </div>
                                    )}
                                    <div
                                        className={`k-form-buttons ${
                                            !isEditing ? 'k-justify-content-between' : 'k-justify-content-end'
                                        }`}>
                                        {!isEditing && <BackButton text={t('button.back')} />}
                                        <Button
                                            themeColor={'primary'}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}>
                                            {isEditing ? t('button.save') : t('createGroupe')}
                                        </Button>
                                    </div>
                                </fieldset>
                            </FormElement>
                        )}
                    />
                </>
            )}
        </div>
    );
}
