/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable testing-library/no-node-access */
/* eslint-disable max-lines-per-function */
import { createRef, useEffect, useState, useContext, useRef, Dispatch, SetStateAction } from 'react';
import './conversation.css';
import MessageList from '../MessageList/MessageList';
import MessageInput from '../MessageInput/MessageInput';
import { ConversationContext } from '../../Contexts/conversationIdContext';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { useTranslation } from 'react-i18next';
import UseUserAttributes from '../../hooks/useUserAttributes';
import axios, { AxiosResponse } from 'axios';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { fetchAvailabilityData } from '../../common/Availability';
import UnavailableInput from '../UnavailableInput/UnavailableInput';
import {
    ReadMessageNotificationType,
    ReceivedMessageNotificationType,
    hasConversationChangedSilentNotification,
    isStatusChanged,
} from '../../common/notificationsUtil';
import { NotificationsContext } from '../../Contexts/notificationsContext';
import { IgetAllMessageData, IRecipient } from './IConversation';
import {
    getReadCount,
    obtainInterlocutorReadDate,
    obtainInterlocutorReceivedDate,
} from '../../common/messageInfoFormat';
import {
    removeDuplicatesMessageList,
    markMessagesAsReceivedRead,
    IMarkMessagesAsReceivedReadResult,
    extractMinimalForwardMessageInfo,
    extractForwardedMessageIdAndText,
    createDeepCopyOfMessageContent,
    IMinimalForwardedCopiedInfo,
    IMinimalForwardedInfo,
    extractForwardDocument,
    addReplyOrForwardDataToDocumentList,
} from '../../common/messageUtil';

import {
    createUsersApi,
    ApiMsConversations,
    createConversationsApi,
    createMessagesApi,
    buildLogsAndPostToIndexedDB,
    settings,
    useAuth,
} from 'idside-core';
import { INotification } from '../../services/notifications/INotification';
import { DocumentDataRequest, Document, MessageContent } from '../Message/Message';
import { MessageContextMenuActionType, MessageContextMenuActionTypeEnum, MimeTypeEnum } from '../commonData';
import { Buffer } from 'buffer';
import { retryFunc } from '../../common/retryFunction';
import { downloadFiles } from '../../common/DownloadFile';
import { isAnInternalDocumentMimeType } from '../commonData';
import { SvgIcon } from '@progress/kendo-react-common';
import { caretAltDownIcon } from '@progress/kendo-svg-icons';

interface IConversation {
    setConversations: Dispatch<SetStateAction<ApiMsConversations.ConversationResponse[]>>;
    conversations: ApiMsConversations.ConversationResponse[];
    conversationIdParam: string | undefined;
}

interface IrecipientUsersInfo {
    id: string;
    type: string;
    userName: string;
}

interface IRecipientKeys {
    key: string;
    id: string;
}

export interface IPostMessage extends ApiMsConversations.MessageResponse {}

const Conversation = ({ conversations, setConversations, conversationIdParam }: IConversation) => {
    const [t] = useTranslation(['message']);

    const {
        clickedConversation,
        conversationId: conversationIDContext,
        setCurrentConversationId,
    } = useContext(ConversationContext);
    const {
        orgID: currentOrganisationId,
        currentUserId,
        accountId,
        currentUserName,
        impersonatorName,
        setError,
        setErrorContent,
        firebaseNotifications,
        // lastVirtualTeam,
    } = useContext(GlobalInfoContext);
    const [currentMessage, setCurrentMessage] = useState<string>('');
    const [messageList, setMessageList] = useState<IgetAllMessageData[]>([]);
    //copier coller texte et image
    const [copiedMessage, setCopiedMessage] = useState<any>();
    const [isShown, setIsShown] = useState<boolean>(false);
    const [pasteInput, isPasteInput] = useState<boolean>(true);
    const [currentImage, setCurrentImage] = useState<string>('');
    const [tempImageList, setTempImageList] = useState<Document[]>([]);
    const [imageList, setImageList] = useState<Document[]>([]);
    const [count, setCount] = useState<any>(0);
    const [showCount, isShowCount] = useState<boolean>(false);

    //variables pour API et messages
    const [newMessageID, setNewMessageID] = useState<any>('');
    const [recipientsKeys, setRecipientsKeys] = useState<IRecipientKeys[]>([]);
    const [previousKeys, setPreviousKeys] = useState<IRecipientKeys[]>([]);
    const [recipientsUsersInfos, setRecipientsUsersInfos] = useState<IrecipientUsersInfo[]>();
    const [loading, setLoading] = useState(false);
    const [conversationName, setConversationName] = useState<any>();
    const { getToken, authInstance } = useAuth();
    const [fetchGetPersonalData] = UseUserAttributes(currentOrganisationId, getToken() ?? '');
    //variable pour disponibilite
    const [showUnavailable, setShowUnavailable] = useState<boolean>();
    const [availabilityDate, setAvailabilityDate] = useState<string>();
    const [isDeleted, setIsDeleted] = useState<boolean>();
    const [userName, setUserName] = useState<string>();
    const [recipients, setRecipients] = useState<string[]>();
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [isDisplayResponseMessageBox, setIsDisplayResponseMessageBox] = useState<boolean>(false);
    const [textOnlyInput, isTextOnlyInput] = useState<boolean>(false);
    const [previousConversationIDContext, setPreviousConversationIDContext] = useState<string | undefined>();
    const [previousRecipientUsersInfo, setPreviousRecipientUsersInfo] = useState<IrecipientUsersInfo[]>();
    const [nonAvailableNameData, setNonAvailableData] = useState<string>();
    const [recipientsUpdated, setRecipientsUpdated] = useState<boolean>(false);
    const [hideScrollBottom, setHideScrollBottom] = useState<boolean>(false);
    const [impersonatorIdList, setImpersonatorIdList] = useState<string[]>([]);
    const [messageImpersonatorName, setMessageImpersonatorName] = useState<IgetAllMessageData>();
    const [newMessage, isNewMessage] = useState<boolean>(false);
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const [latestMessageDate, setLatestMessageDate] = useState<string | undefined>();
    const [noRecentMessages, setNoRecentMessages] = useState<boolean>(false);
    const { sethasReadNewMessage } = useContext(NotificationsContext);
    const [, setPhotoURL] = useState<string>('');
    const [updateStatus, setUpdateStatus] = useState<boolean>(false);
    const [originMessageInfo, setOriginMessageInfo] = useState<MessageContent | undefined>();
    const [originalForwardMessageInfo, setOriginalForwardMessageInfo] = useState<
        IMinimalForwardedCopiedInfo | undefined
    >();

    const [actionType, setActionType] = useState<MessageContextMenuActionType>(
        MessageContextMenuActionTypeEnum.NoAction,
    );
    const [originMessageImageList, setOriginMessageImageList] = useState<Document[]>([]);
    const [messageTextReplyTo, setMessageTextReplyTo] = useState<string>('');
    const [messageCreateDateReplyTo, setMessageCreateDateReplyTo] = useState<string>('');
    const [messageAuthorReplyTo, setMessageAuthorReplyTo] = useState<string>('');
    const [messageAuthorIdReplyTo, setMessageAuthorIdReplyTo] = useState<string>('');
    const [isReplyOfForward, setIsReplyOfForward] = useState<boolean>(false);
    const [simpleMessageDocumentList, setSimpleMessageDocumentList] = useState<Document[]>([]);
    //TODO: Temporary. Back-End will give us a new error code.
    const messageError: string = "Conversation recipients and request recipients doesn't match in size!";

    const Copy = async (messageData?: MessageContent) => {
        if (messageData) {
            clearMessageInput(true);

            let messageDataForCopy = createDeepCopyOfMessageContent(
                messageData,
                MessageContextMenuActionTypeEnum.Forward,
            );

            const msg = extractForwardedMessageIdAndText(messageDataForCopy);
            let forwardMessageId: string | undefined;
            let textCopied;
            if (msg && msg !== undefined) {
                setOriginalForwardMessageInfo(msg);
                forwardMessageId = msg.id;
                textCopied = msg.text;
            } else {
                textCopied = messageDataForCopy.message;
            }
            navigator.clipboard.writeText(textCopied || '');
            setCopiedMessage(textCopied);
            setIsShown(true);

            let docs: Document[] = extractForwardDocument(messageDataForCopy, forwardMessageId).slice();
            let docsCopy: Document[] = [];
            docs.forEach((doc) => {
                docsCopy.push({
                    id: doc.id,
                    mimeType: doc.mimeType,
                    thumbnail: doc.thumbnail,
                    data: doc.data,
                    filename: doc.filename,
                    orderNumber: doc.orderNumber,
                    size: doc.size,
                    referenceMessageId: undefined,
                    copyInitiator: currentUserId,
                });
            });

            setTempImageList(docsCopy);
            setActionType(MessageContextMenuActionTypeEnum.Copy);
        }
    };
    const Paste = async () => {
        setIsReplyOfForward(false);
        setIsShown(false);
        setCurrentMessage(copiedMessage);
        setSimpleMessageDocumentList(tempImageList);
    };

    const Reply = (messageData?: MessageContent) => {
        if (messageData) {
            clearMessageInput(true);
            setActionType(MessageContextMenuActionTypeEnum.Reply);

            let messageDataForReply = createDeepCopyOfMessageContent(
                messageData,
                MessageContextMenuActionTypeEnum.Forward,
            );

            let forwardDocument = messageDataForReply.documents.find(
                (elt) => elt.mimeType === MimeTypeEnum.FORWARD && elt.referenceMessageId === undefined,
            );

            if (forwardDocument) {
                const forwardDocumentData = forwardDocument.data
                    ? JSON.parse(Buffer.from(forwardDocument.data, 'base64').toString())
                    : undefined;
                let forwardMessageId: string | undefined;

                if (forwardDocumentData && forwardDocumentData !== undefined) {
                    forwardMessageId = forwardDocumentData.id;
                    let nonInternalDocumentsInForward = extractMinimalForwardMessageInfo(
                        messageDataForReply,
                        forwardMessageId,
                    ).slice();

                    nonInternalDocumentsInForward.push(
                        Object.assign(
                            {},
                            {
                                orderNumber: 1,
                                mimeType: MimeTypeEnum.FORWARD,
                                data: Buffer.from(JSON.stringify(forwardDocumentData)).toString('base64'),
                                referenceMessageId: messageDataForReply.id,
                            },
                        ),
                    );
                    setIsReplyOfForward(true);

                    setOriginMessageImageList(nonInternalDocumentsInForward);
                    setMessageTextReplyTo(forwardDocumentData.text);
                    setMessageAuthorReplyTo(forwardDocumentData.auteur);
                    setMessageAuthorIdReplyTo(forwardDocumentData.auteurId);
                    setMessageCreateDateReplyTo(forwardDocumentData.createdDate);
                } else {
                    setOriginMessageImageList(extractMinimalForwardMessageInfo(messageDataForReply, undefined).slice());
                    setMessageCreateDateReplyTo(messageDataForReply.createdDate);
                }
            } else {
                setOriginMessageImageList(extractMinimalForwardMessageInfo(messageDataForReply, undefined).slice());
                setMessageTextReplyTo(messageDataForReply.message);
                setMessageCreateDateReplyTo(messageDataForReply.createdDate);
            }
            setOriginMessageInfo(messageDataForReply);
            setCurrentMessage('');
            setImageList([]);
            setIsDisplayResponseMessageBox(true);
        }
    };

    const Forward = (messageData?: MessageContent) => {
        if (messageData) {
            clearMessageInput(true);
            setActionType(MessageContextMenuActionTypeEnum.Forward);

            let messageDataForForward = createDeepCopyOfMessageContent(
                messageData,
                MessageContextMenuActionTypeEnum.Forward,
            );

            const internalForwardMessage = extractForwardedMessageIdAndText(messageDataForForward);

            let forwardMessageId: string | undefined;
            if (internalForwardMessage && internalForwardMessage !== undefined) {
                setOriginalForwardMessageInfo(internalForwardMessage);
                forwardMessageId = internalForwardMessage.id;
            }

            const extractResult = extractMinimalForwardMessageInfo(messageDataForForward, forwardMessageId);
            extractResult.forEach((doc: Document) => {
                doc.referenceMessageId = forwardMessageId ? internalForwardMessage?.id : messageDataForForward.id;
            });
            setOriginMessageImageList(extractResult);

            setOriginMessageInfo(messageDataForForward);
            setMessageTextReplyTo(messageDataForForward.message);
            setMessageCreateDateReplyTo(messageDataForForward.createdDate);
        }
    };

    const DisplayResponseMessageBox = () => {
        setActionType(MessageContextMenuActionTypeEnum.Copy);
        setImageList([]);
        setOriginMessageImageList([]);
        setIsDisplayResponseMessageBox(false);
    };

    /*Axios API GET et POST request: */
    let currentName: string;
    if (currentUserName) {
        currentName = currentUserName;
    } else {
        currentName = impersonatorName;
    }

    function removeDuplicates(arr: string[]) {
        let unique: string[] = [];
        arr.forEach((element: string) => {
            if (!unique.includes(element)) {
                unique.push(element);
            }
        });
        return unique;
    }

    //Reset loader when changing virtualTeams or org
    useEffect(() => {
        setLoading(false);
    }, [currentUserId, currentOrganisationId]);

    const getConversationRecipientsIds = async () => {
        const start = new Date().getTime();
        if (
            (conversationIDContext !== previousConversationIDContext && conversationIDContext !== '') ||
            recipientsUpdated
        ) {
            if (getToken()) {
                try {
                    // const postRecipientArray: any[] = [];
                    if (conversationIDContext !== '' && !recipientsUpdated) {
                        setLoading(true);
                    }

                    const conversationsApi = await createConversationsApi(
                        authInstance!,
                        currentOrganisationId,
                    );
                    const { data }: AxiosResponse = 
                    await conversationsApi.getConversationById(conversationIDContext);
                    setConversationName(data.name);
                    setRecipients(data.recipients);

                    /*
                    -- Testing with promise.all for fetching data
                    */
                    const recipientsPromises = data.recipients.map((recipient: { id: string }) =>
                        fetchGetPersonalData(recipient?.id),
                    );
                    const recipientsResults = await Promise.all(recipientsPromises);
                    const postRecipientArray = recipientsResults.map((recipient, index) => ({
                        key: recipient.key,
                        id: data.recipients[index].id,
                    }));
                    setRecipientsKeys(postRecipientArray);
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - start,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        currentUserId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    setErrorContent(err);
                    setError(true);
                }
            }
        }
    };

    useEffect(() => {
        if (conversationIDContext !== undefined) {
            getConversationRecipientsIds();
        }
    }, [recipientsUpdated, conversationIDContext, currentOrganisationId, setErrorContent, setError, currentUserId]);

    useEffect(() => {
        const getUserNameArray: any[] = [];
        const start = new Date().getTime();
        if (recipientsKeys) {
            const getReceiveUser = async () => {
                if (getToken()) {
                    for (let i = 0; i < recipientsKeys?.length; i++) {
                        const usersApi = await createUsersApi(authInstance!, currentOrganisationId);
                        await usersApi
                            .getUserSummaryById(recipientsKeys[i].id!, currentOrganisationId)
                            .then((res: any) => {
                                const formattedUser = Object.assign(
                                    {},
                                    { id: res.data.id, userName: res.data.name, type: res.data.type },
                                );
                                if (res.data.id !== currentUserId) {
                                    setNonAvailableData(res.data.name);
                                }
                                const userNameStringify = JSON.stringify(recipientsUsersInfos);
                                const resultStringify = JSON.stringify(res.data);
                                if (!userNameStringify?.includes(resultStringify)) {
                                    const userResults = { ...formattedUser };

                                    getUserNameArray.push(userResults);

                                    setRecipientsUsersInfos(getUserNameArray);
                                }
                            })
                            .catch(async (err: any) => {
                                await buildLogsAndPostToIndexedDB(
                                    err.message,
                                    err.stack,
                                    new Date().getTime() - start,
                                    settings.appId,
                                    window.location.origin,
                                    err.config.headers.trackingId,
                                    err.config[`axios-retry`].retries,
                                    authInstance?.sessionId!,
                                    currentUserId,
                                    err.config.headers.deviceId,
                                    err.config.headers.organisationId,
                                    err.config.headers.provider,
                                    settings.dbName,
                                    settings.storeIdTexto,
                                );
                                console.error('getReceiveUser Error', err);
                                setErrorContent(err);
                                setError(true);
                            });
                    }
                }
            };
            const loadNames = () => {
                if (recipientsKeys.length > 1 && previousKeys !== recipientsKeys) {
                    setPreviousKeys(recipientsKeys);
                    getReceiveUser();
                }
            };

            loadNames();
        }
    }, [recipientsKeys, conversationIDContext, setError, setErrorContent, currentUserId]);

    const updateMessages = async (
        abortController: AbortController | undefined,
        markMessagesAsRead: boolean = false,
    ) => {
        let localLatestMessageDate: string | undefined = latestMessageDate;
        const start = new Date().getTime();
        if (conversationIDContext !== previousConversationIDContext) {
            localLatestMessageDate = undefined;
            setPreviousConversationIDContext(conversationIDContext);
            setIsDisabled(true);
            setNoRecentMessages(false);
        }

        //Run getMessages if we load a conversation or have a new message
        const getMessages = async () => {
            if (conversationIDContext === previousConversationIDContext && conversationIDContext !== '') {
                if (getToken()) {
                    try {
                        //
                        // Fetch messages (all or new depending if a Latest Message Date is passed)
                        //
                        const conversationsApi = await createConversationsApi(
                            authInstance!,
                        );
                        const { data }: AxiosResponse = await conversationsApi.getAllCurrentUserMessagesOfConversationV3(
                            conversationIDContext, //id
                            currentUserId, //currentUserId, //userId
                            currentOrganisationId, //organisationId
                            false, // return document data
                            undefined, // isDecryptionActive
                            undefined, // beforeCreatedDate
                            localLatestMessageDate, // afterCreatedDate
                            undefined, //maxElement
                            { signal: abortController?.signal }, //options
                        );

                        const messageResponses: IgetAllMessageData[] = data;

                        const newMessages = messageResponses.map((message: IgetAllMessageData) => {
                            const getRecipientName = (authorId: string) => {
                                if (authorId === currentUserId) {
                                    return currentName;
                                } else {
                                    const recipientUserInfo = recipientsUsersInfos?.find(
                                        (recipientUser: IRecipient) => recipientUser.id === authorId,
                                    )?.userName;

                                    return recipientUserInfo;
                                }
                            };

                            let decodedMessage;
                            try {
                                decodedMessage = message.messageData;
                            } catch (err) {
                                decodedMessage = t('invalidMessage', { ns: 'message' });
                            }

                            const messageRecipients = message.recipients.map((recipient: IRecipient) => {
                                return recipient;
                            });

                            if (message.impersonatorId !== message.authorId) {
                                setImpersonatorIdList((organisationName: any) => [
                                    ...organisationName,
                                    message.impersonatorId,
                                ]);
                            }

                            const messageData: IgetAllMessageData = {
                                user: getRecipientName(message.authorId),
                                message: decodedMessage,
                                documents: message.documents.sort((a: Document, b: Document) => {
                                    const orderA = a?.orderNumber ?? 0;
                                    const orderB = b?.orderNumber ?? 0;
                                    return orderA - orderB;
                                }),
                                createdDate: message.createdDate,
                                interlocutorReceivedDate: obtainInterlocutorReceivedDate(
                                    message,
                                    currentUserId,
                                    conversationName !== undefined,
                                ),
                                interlocutorReadDate: obtainInterlocutorReadDate(
                                    message,
                                    currentUserId,
                                    conversationName !== undefined,
                                ),
                                decrypted: message.decrypted,
                                recipients: messageRecipients,
                                id: message.id,
                                organisationId: currentOrganisationId,
                                authorId: message.authorId,
                                impersonatorId: message.impersonatorId,
                                name: message.name,
                                readCount: getReadCount(message),
                                messageRef: createRef(),
                            };

                            return messageData;
                        });
                        //
                        // Merge new message with old (if there are any)
                        //
                        let distinctMessageList: IgetAllMessageData[] = [];
                        if (localLatestMessageDate === undefined) {
                            distinctMessageList = newMessages;
                        } else {
                            distinctMessageList = removeDuplicatesMessageList([...messageList, ...newMessages]);
                        }

                        //Update right away the message list with the new list of messages. On slower computer or network,
                        //this will give a first feedback to the user before we update the status (if applicable)
                        setMessageList(distinctMessageList);

                        if (localLatestMessageDate === undefined) {
                            setIsDisabled(false);
                            setLoading(false);
                        }

                        if (messageResponses.length !== 0) {
                            localLatestMessageDate = messageResponses[messageResponses.length - 1].createdDate;
                        }

                        if (newMessages.length === 0) {
                            setNoRecentMessages(true);
                        }

                        //
                        // Mark messages as received or read if applicable
                        //
                        if (conversationIDContext && localLatestMessageDate && distinctMessageList?.length > 0) {
                            let markMessagesAsReceivedReadResult: IMarkMessagesAsReceivedReadResult =
                                await markMessagesAsReceivedRead(
                                    conversationIDContext,
                                    currentUserId,
                                    currentOrganisationId,
                                    localLatestMessageDate,
                                    distinctMessageList,
                                    markMessagesAsRead,
                                    newMessages?.length > 0,
                                    authInstance,
                                );

                            distinctMessageList = markMessagesAsReceivedReadResult.messages;
                            setMessageList(distinctMessageList);

                            if (markMessagesAsReceivedReadResult.needToUpdateReadDate) {
                                sethasReadNewMessage(true);
                            }
                        }
                    } catch (err: any) {
                        if (axios.isCancel(err)) {
                            return 'axios request cancelled';
                        }
                        await buildLogsAndPostToIndexedDB(
                            err.message,
                            err.stack,
                            new Date().getTime() - start,
                            settings.appId,
                            window.location.origin,
                            err.config.headers.trackingId,
                            err.config[`axios-retry`].retries,
                            authInstance?.sessionId!,
                            currentUserId,
                            err.config.headers.deviceId,
                            err.config.headers.organisationId,
                            err.config.headers.provider,
                            settings.dbName,
                            settings.storeIdTexto,
                        );
                        console.log('Error obtaining messages from conversation "', conversationIDContext, '" : ', err);
                        setLoading(false);
                        setErrorContent(err);
                        setError(true);
                    }
                }
            }

            setLatestMessageDate(localLatestMessageDate);
        };

        if (recipientsUsersInfos) {
            await retryFunc(getMessages, []).catch((err) => {
                setError(true);
                setErrorContent(err);
            });
        }

        if (recipientsUsersInfos !== previousRecipientUsersInfo) {
            setPreviousRecipientUsersInfo(recipientsUsersInfos);
        }
    };

    //TODO: Pass notifications as parameters
    const updateMessageStatusFromNotifications = () => {
        firebaseNotifications?.forEach((notification: INotification | undefined) => {
            let messageId: string;
            let cibleId: string;
            if (notification?.data !== undefined) {
                if (notification?.silent === true) {
                    const notificationType = JSON.parse(notification.data)['type'];
                    if (
                        notificationType === ReadMessageNotificationType ||
                        notificationType === ReceivedMessageNotificationType
                    ) {
                        if (JSON.parse(notification.data)['conversationId'] === conversationIDContext) {
                            messageId = JSON.parse(notification.data)['messageId'];
                            cibleId = JSON.parse(notification.data)['cibleId'];
                            if (messageId !== undefined) {
                                messageList.find((message: IgetAllMessageData) => {
                                    if (message.id === messageId) {
                                        message.recipients.find((recipient: IRecipient) => {
                                            if (recipient.id === cibleId) {
                                                if (notificationType === ReadMessageNotificationType) {
                                                    const date: string = JSON.parse(notification.data!)['luLe'];
                                                    recipient.readDate = date;
                                                    if (recipient.id === currentUserId) {
                                                        message.currentUserReadDate = date;
                                                        sethasReadNewMessage(true);
                                                    } else {
                                                        message.interlocutorReadDate = date;
                                                    }
                                                    message.readCount = getReadCount(message);
                                                } else {
                                                    //ReceivedMessageNotificationType
                                                    const date: string = JSON.parse(notification.data!)['transmisLe'];
                                                    recipient.receivedDate = date;
                                                    message.interlocutorReceivedDate = date;
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        }
                    }
                }
            }
        });
    };

    //On call le getAlert is on a un message lu si on est loggé en tant qu'une équipe virtuelle
    useEffect(() => {
        updateStatus && sethasReadNewMessage(true);
    }, [updateStatus]);

    useEffect(() => {
        if (conversationIDContext === '') {
            setPreviousConversationIDContext('');
            setMessageList([]);
            if (loading) setLoading(false);
            setLatestMessageDate(undefined);
        }
        setHideScrollBottom(true);
    }, [conversationIDContext]);

    const clearMessageInput = (clearCopyInfos: boolean) => {
        if (clearCopyInfos) {
            setTempImageList([]); //Copied image list
            setCopiedMessage(undefined); //Copied text
            setIsReplyOfForward(false); //Info about the message that was copied
            setIsShown(false); //Display Paste button
            setActionType(MessageContextMenuActionTypeEnum.NoAction);
        }

        setIsDisplayResponseMessageBox(false); //Reply section
        setImageList([]); //Documents

        isTextOnlyInput(true);

        isShowCount(false);
        setCount(0);
        setCurrentMessage('');
        setCurrentImage('');
        setImageList([]);
        setSimpleMessageDocumentList([]);

        setMessageAuthorReplyTo('');
        setMessageAuthorIdReplyTo('');
        setMessageCreateDateReplyTo('');

        //Informations used by Forward
        setOriginalForwardMessageInfo(undefined); //Info about the message being forwarded
        setOriginMessageInfo(undefined); //Info about the message being forwarded
        setMessageTextReplyTo(''); //Reply or forward message text
    };

    //Loading impersonatorNames:
    useEffect(() => {
        const impersonatorIdUniqueList = removeDuplicates(impersonatorIdList);
        let impersonatorArray: any = [];
        //assign impersonatorData

        impersonatorIdUniqueList.forEach(async (uniqueImpersonatorId: string) => {
            if (uniqueImpersonatorId !== accountId) {
                const usersApi = await createUsersApi(authInstance!, currentOrganisationId);
                const result = await usersApi.getUserSummaryById(
                    uniqueImpersonatorId,
                    currentOrganisationId,
                );
                const impersonatorResults = { ...result.data };
                impersonatorArray.push(impersonatorResults);
                setMessageImpersonatorName(impersonatorArray);
            }
        });
    }, [impersonatorIdList]);
    useEffect(() => {
        const abortController = new AbortController();
        if (clickedConversation.clickedEventId !== '' && clickedConversation.conversationId !== '') {
            //If we switched conversation by clicking on it and not because of a forward redirecting us
            if (actionType !== MessageContextMenuActionTypeEnum.Forward) {
                clearMessageInput(false);
            }

            retryFunc(updateMessages, [abortController, true]).catch((err) => {
                setError(true);
                setErrorContent(err);
            });
        } else {
            retryFunc(updateMessages, [abortController, false]).catch((err) => {
                setError(true);
                setErrorContent(err);
            });
        }

        return () => {
            abortController.abort(); // abort for cleanup
        };
    }, [clickedConversation, recipientsUsersInfos]);

    useEffect(() => {
        retryFunc(updateMessages, [undefined, false]).catch((err) => {
            setError(true);
            setErrorContent(err);
        });
    }, [newMessageID, conversationIdParam]);

    useEffect(() => {
        setCurrentMessage('');
        setImageList([]);
        if (actionType === MessageContextMenuActionTypeEnum.Reply) {
            setIsDisplayResponseMessageBox(false);
            setActionType(MessageContextMenuActionTypeEnum.NoAction);
        }
    }, [newMessageID, conversationIdParam]);

    useEffect(() => {
        //Update the message when receiving a silent notification for the current conversation
        if (hasConversationChangedSilentNotification(firebaseNotifications, conversationIDContext)) {
            //TODO: Update only when applicable (i.e. new message)
            //TODO: Add await to retryFunc and check if we should add to all retry func
            retryFunc(updateMessages, [undefined, false]).catch((err) => {
                setError(true);
                setErrorContent(err);
            });
            //TODO: Update only when applicable (message read)
            retryFunc(updateMessageStatusFromNotifications, []).catch((err) => {
                setError(true);
                setErrorContent(err);
            });
        }
    }, [firebaseNotifications]);

    const sendMessage = async () => {
        setIsDisabled(true);

        const start = new Date().getTime();
        if (
            currentMessage !== '' ||
            imageList.length > 0 ||
            simpleMessageDocumentList.length > 0 ||
            actionType === MessageContextMenuActionTypeEnum.Forward
        ) {
            const messageData = {
                user: currentName,
                message: currentMessage,
                img: imageList,
            };

            let replyForwardDocumentList: Document[] = [];

            replyForwardDocumentList = messageData.img;

            let downloadFileData: Document[] | undefined;
            if (simpleMessageDocumentList?.length > 0) {
                let orderNumber = 1;
                simpleMessageDocumentList.forEach((document) => {
                    document.orderNumber = orderNumber++;
                });

                downloadFileData = await downloadFiles(
                    simpleMessageDocumentList,
                    currentOrganisationId,
                    currentUserId,
                    authInstance,
                );
            }

            addReplyOrForwardDataToDocumentList(actionType,
                originalForwardMessageInfo,
                originMessageInfo,
                downloadFileData,
                replyForwardDocumentList);

            if (getToken()) {
                let postCompletedSuccessfully: boolean = false;
                try {
                    //TODO: Make sure orderNumber is incremented correctly
                    const postMessageRequest: IPostMessage = {
                        messageData: messageData.message,
                        conversationId: conversationIDContext,
                        impersonatorId: accountId,
                        authorId: currentUserId,
                        recipients: recipientsKeys,
                        documents:
                            actionType === MessageContextMenuActionTypeEnum.Reply ||
                            actionType === MessageContextMenuActionTypeEnum.Forward
                                ? (replyForwardDocumentList as DocumentDataRequest[])
                                : downloadFileData !== undefined
                                ? downloadFileData
                                : (simpleMessageDocumentList as DocumentDataRequest[]),
                    };

                    const messagesCreateApi = await createMessagesApi(authInstance!);
                    const { data: createdMessageResponse }: AxiosResponse<ApiMsConversations.CreatedMessageResponse> =
                            await messagesCreateApi.postMessageV2(
                            currentOrganisationId,
                            currentUserId,
                            postMessageRequest as ApiMsConversations.MessageRequest,
                            true,
                        );
                    setNewMessageID(createdMessageResponse.id);

                    postCompletedSuccessfully = true;
                } catch (err: any) {
                    //reload function if the recipient list changes
                    try {
                        if (err?.response?.data?.message === messageError) {
                            console.log('Need to update recipients');
                            await getConversationRecipientsIds();
                            setRecipientsUpdated(true);
                        } else {
                            throw err;
                        }
                    } catch (error: any) {
                        await buildLogsAndPostToIndexedDB(
                            err.message,
                            err.stack,
                            new Date().getTime() - start,
                            settings.appId,
                            window.location.origin,
                            err.config.headers.trackingId,
                            err.config[`axios-retry`].retries,
                            authInstance?.sessionId!,
                            currentUserId,
                            err.config.headers.deviceId,
                            err.config.headers.organisationId,
                            err.config.headers.provider,
                            settings.dbName,
                            settings.storeIdTexto,
                        );
                        console.log('Error sending message: ', error);
                        setErrorContent(error);
                        setError(true);
                    }
                }

                if (postCompletedSuccessfully) {
                    clearMessageInput(true);
                    scrollToBottom();
                }
            } else {
                throw Error('User authentifcation not valid');
            }
        }

        setIsDisabled(false);
    };

    useEffect(() => {
        const resendMessageNewRecipients = async () => {
            await sendMessage();
            setRecipientsUpdated(false);
            setIsDisabled(false);
        };
        //If someone leaves group discussion
        if (recipientsUpdated === true && recipientsKeys.length > 1) {
            resendMessageNewRecipients();
        }
    }, [recipientsUpdated, recipientsKeys?.length]);

    //Show non-availability of person
    useEffect(() => {
        fetchAvailabilityData(
            recipients,
            currentOrganisationId,
            getToken(),
            setError,
            setErrorContent,
            setShowUnavailable,
            setAvailabilityDate,
            setIsDeleted,
            currentUserId,
            setPhotoURL,
            authInstance,
        );
    }, [recipients, getToken(), setError, setErrorContent]);

    useEffect(() => {
        if (recipients && isStatusChanged(firebaseNotifications, recipients)) {
            fetchAvailabilityData(
                recipients,
                currentOrganisationId,
                getToken(),
                setError,
                setErrorContent,
                setShowUnavailable,
                setAvailabilityDate,
                setIsDeleted,
                currentUserId,
                setPhotoURL,
                authInstance,
            );
        }
    }, [firebaseNotifications]);

    useEffect(() => {
        setUserName(nonAvailableNameData);
    }, [nonAvailableNameData]);

    useEffect(() => {
        if (simpleMessageDocumentList?.length > 0) {
            isTextOnlyInput(false);
        } else {
            isTextOnlyInput(true);
        }
    }, [simpleMessageDocumentList?.length]);

    const handleScroll = (e: any) => {
        if (messageList.length > 1) {
            const bottom = e.target.scrollHeight - e.target.scrollTop > 1100;

            if (bottom && hideScrollBottom) {
                setHideScrollBottom(false);
            } else if (!bottom && !hideScrollBottom) {
                setHideScrollBottom(true);
            }
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current !== null) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (messageList.length > 1) {
            //TODO: Use a find instead since we only to check if at least one messge is unread
            const filtredListMessage = messageList.filter((message: IgetAllMessageData) => {
                const currentRecepientUser = message.recipients.find(
                    (recipient: IRecipient) => recipient.id === currentUserId,
                );
                return currentRecepientUser?.readDate === undefined;
            });

            if (filtredListMessage.length === 0) {
                isNewMessage(false);
            } else {
                isNewMessage(true);
            }
        }
    }, [messageList]);

    return (
        <>
            <div onScroll={handleScroll} className="chat-feed" data-testid="chat-feed">
                <div>
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <span className="k-drawer-items"></span>
                            <div className="chat-mid">
                                {conversationIDContext === previousConversationIDContext && (
                                    <MessageList
                                        messageList={messageList}
                                        copy={Copy}
                                        reply={Reply}
                                        forward={Forward}
                                        pasteInput={pasteInput}
                                        newRecipientArray={recipientsKeys}
                                        getConversationName={conversationName}
                                        setMessageList={setMessageList}
                                        messageImpersonatorName={messageImpersonatorName}
                                        impersonatorIdList={impersonatorIdList}
                                        hideScrollBottom={hideScrollBottom}
                                        conversations={conversations}
                                        setConversations={setConversations}
                                        noRecentMessages={noRecentMessages}
                                        sendMessage={sendMessage}
                                        setActionType={setActionType}
                                        actionType={actionType}
                                    />
                                )}
                            </div>
                            <div className="bottom-chat" ref={messagesEndRef}></div>
                        </>
                    )}
                </div>
            </div>
            <div className={textOnlyInput ? 'chat-footer' : 'chat-footer-big-image'}>
                {!hideScrollBottom && conversationIDContext && (
                    <div className="scroll-to-bottom-container">
                        <div
                            className={newMessage ? 'scroll-to-bottom-button-message' : 'scroll-to-bottom-button'}
                            onClick={scrollToBottom}>
                            <SvgIcon
                                icon={caretAltDownIcon}
                                style={{ transform: 'scale(1.2)', marginLeft: '1px' }}></SvgIcon>
                            {/* TODO : Appporter les changements inline sous forme de classe */}
                            {newMessage && <span>{t('newMessages')}</span>}
                        </div>
                    </div>
                )}
                {(showUnavailable && conversationName === undefined && conversationIDContext) ||
                (isDeleted && conversationName === undefined) ? (
                    <UnavailableInput userName={userName} availabilityDate={availabilityDate} isDeleted={isDeleted} />
                ) : (
                    <MessageInput
                        sendMessage={sendMessage}
                        isShown={isShown}
                        imageList={
                            actionType === MessageContextMenuActionTypeEnum.Reply
                                ? originMessageImageList.filter(
                                      (document) => !isAnInternalDocumentMimeType(document.mimeType),
                                  )
                                : imageList
                        }
                        Paste={Paste}
                        setImageList={
                            actionType === MessageContextMenuActionTypeEnum.Reply
                                ? setOriginMessageImageList
                                : setImageList
                        }
                        setCurrentImage={setCurrentImage}
                        currentImage={currentImage}
                        isPasteInput={isPasteInput}
                        currentMessage={currentMessage}
                        setCurrentMessage={setCurrentMessage}
                        showCount={showCount}
                        isShowCount={isShowCount}
                        count={count}
                        setCount={setCount}
                        newRecipientArray={recipientsKeys ? recipientsKeys : []}
                        getConversationName={conversationName}
                        getUserNameArray={recipientsUsersInfos}
                        isDisabled={isDisabled}
                        textOnlyInput={textOnlyInput}
                        loading={loading}
                        replyTo={
                            messageAuthorReplyTo.length > 0
                                ? messageAuthorReplyTo
                                : originMessageInfo?.messageAutorFullName
                        }
                        dateOfMessageBeingRepliedTo={
                            messageCreateDateReplyTo.length > 0
                                ? messageCreateDateReplyTo
                                : originMessageInfo?.createdDate
                        }
                        messageTextBeingRepliedTo={
                            originalForwardMessageInfo ? originMessageInfo?.message : messageTextReplyTo
                        }
                        actionType={actionType}
                        DisplayResponseMessageBox={DisplayResponseMessageBox}
                        isDisplayResponseMessageBox={isDisplayResponseMessageBox}
                        simpleMessageDocumentList={simpleMessageDocumentList}
                        setSimpleMessageDocumentList={setSimpleMessageDocumentList}
                    />
                )}
            </div>
        </>
    );
};

export default Conversation;
