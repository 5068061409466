import { useAuth } from 'idside-core';

type Props = {
    children?: any;
};

const RenderOnInitialized = ({ children }: Props) => {
    const { authInstance } = useAuth();
    return authInstance ? children : null;
};

export default RenderOnInitialized;
