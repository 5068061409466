import { useCallback, useContext, useEffect, useState } from 'react';
import './kendo-theme-idside.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalInfoContext } from './Contexts/globalInfoContext';
import { NotificationsContext } from './Contexts/notificationsContext';
import ErrorMessage from './component/ErrorMessage/ErrorMessage';

import {
    ApiMsOrganisations,
    createUsersApi,
    ExternalContext,
    getDeviceToken,
    AuthProvider,
    settings,
    useAuth,
    WEB_SERVICE_APPLICATION_ID,
    EchoAuthProvider,
    AuthContext,
    ExternalUrlPath,
} from 'idside-core';
import NotificationMessage from './component/NotificationMessage/NotificationMessage';
import TimeoutMessage from './component/TimeoutMessage/TimeoutMessage';
import { ConversationContext, ClickedConversationInfo } from './Contexts/conversationIdContext';
import MainRouter from './routes';
import { deleteNotificationDevice, updateNotificationDevice } from './common/notificationsUtil';
import { NavbarIdtextoUIContainer } from 'idside-ui';
import { useQueryClient } from '@tanstack/react-query';
import GlobalSpinner from './component/GlobalSpinner';
import {
    ViewChat,
    ViewCreateConversation,
    ViewCreateGroup,
    ViewDefault,
    ViewEulaAgreement,
    ViewLogin,
    ViewOrganization,
} from './common/ViewPath';
import useAutoLogout from './hooks/useAutoLogout';
import { buildLogsAndPostToIndexedDB, useLogger } from 'idside-core';
import { AxiosResponse } from 'axios';
import useCreateConversation from './hooks/useCreateConversation';

// eslint-disable-next-line max-lines-per-function
function App() {
    const [t] = useTranslation(['common', 'validation', 'glossary', 'user']);
    const [, setContextTitle] = useState('');
    const [createConversation, setCreateConversation] = useState(true);
    const [infoGroup, setInfoGroup] = useState(Boolean);
    const [createGroup, setCreateGroup] = useState(Boolean);
    const [isLoggingOut, setIsLoggingOut] = useState(Boolean);
    const [showBell, setShowBell] = useState(Boolean);
    const [goToLogin, setGoToLogin] = useState(false);
    const [isUser] = useState(false);
    const location = useLocation();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    let idleTimerInfo = useAutoLogout();
    const { createOrLoadConversation } = useCreateConversation();
    const {
        orgID,
        currentUserName,
        error,
        impersonatorName,
        currentUserId,
        setError,
        setErrorContent,
        setAccountId,
        setCurrentUserId,
        setImpersonatorName,
        setOrgID,
        accountId,
        setShowNavIcon,
        setSecurityActive,
        showNavIcon,
        setLastVirtualTeamOrganizationId,
        setLastVirtualTeam,
        setCurrentUserName,
        setAlias,
        securityActive,
        orgSelected,
        isOrgSelected,
        setIsConversationActive,
        organisationDefault,
        setOrganisationDefault,
        setAttachmentConfigurations,
        acceptedEula,
        setAcceptedEula,
    } = useContext(GlobalInfoContext);
    const { receivedDataContent } = useContext(ExternalContext);
    const queryStateNavbarGetOrgById = queryClient.getQueryState<
        AxiosResponse<ApiMsOrganisations.OrganisationResponse>
    >(['organisation Id', orgID]);

    let ourLocation = useLocation();

    const getAlias = sessionStorage.alias;
    const [conversationId, setConversationId] = useState('');
    const [isGroup, setIsGroup] = useState(Boolean);
    const [path, setPath] = useState(String);
    const [lastVirtualTeamId] = useState<string>(() => localStorage.getItem('lastVirtualTeamId') ?? '');
    const [lastVirtualTeamOrgId] = useState<string>(() => localStorage.getItem('lastVirtualTeamOrganizationId') ?? '');
    const [clickedConversation, setClickedConversation] = useState<ClickedConversationInfo>({
        conversationId: '',
        clickedEventId: '',
    });
    const auth = useAuth();
    const {
        isLoggedIn: isLoggedInAuthProvider,
        getToken,
        getSafeTokenDurationBeforeRefresh,
        doLogout: logoutAuthProvider,
        authInstance,
        isAuthenticated,
        onAuthLogout,
    } = useAuth();

    useLogger(settings.dbName, settings.storeIdTexto, orgID ? orgID : lastVirtualTeamOrgId, authInstance!);
    const isLoggedIn = isLoggedInAuthProvider;

    const { hasUnreadMessage } = useContext(NotificationsContext);

    useEffect(() => {
        if (location.pathname === '/create-conversation') {
            setShowNavIcon(true);
        }
    }, [location.pathname, setShowNavIcon]);

    useEffect(() => {
        if (orgID !== '' && currentUserId !== '' && isAuthenticated) {
            updateNotificationDevice(currentUserId, orgID, authInstance);
        }
    }, [authInstance, currentUserId, isAuthenticated, orgID]);

    // initialize organisation attachements configuration in globalInfoContext
    useEffect(() => {
        if (queryStateNavbarGetOrgById?.fetchStatus === 'idle' && queryStateNavbarGetOrgById?.status === 'success') {
            setAttachmentConfigurations(queryStateNavbarGetOrgById.data?.data.attachmentConfigurations);
        }
    }, [
        queryStateNavbarGetOrgById?.data,
        queryStateNavbarGetOrgById?.fetchStatus,
        queryStateNavbarGetOrgById?.status,
        setAttachmentConfigurations,
    ]);

    //On fait la vérification si alias est vide on le reremplis par la variable dans le localStorage
    //TODO : Le commentaire ci-dessus est il encore pertinent ? Ou au mauvais endroit ?

    // if (window.top?.name !== '') {
    //     console.log('information du top name:', window.top?.name, ': ici');
    // } else {
    //     console.log('Jai pas de nom dans mon window top');
    // }

    //ca cause des refreshs:
    // if (isLoggedIn()) {
    //     if (window.self !== window.parent) {
    //         if (window.parent != null) {
    //             //faire la vérification ici qu'on doit prendre l'adresse url de l'instance présente et non prendre celui hardcoder
    //             //window.parent.location.href = window.location.origin + '/Chat';
    //             // eslint-disable-next-line no-restricted-globals
    //             parent.location.href = path;
    //             //Todo ajuster la redirection pour se fier a la connexion de l'usager
    //         }
    //     }
    // }

    //TODO: Begin Performance issue
    // Refac : Not best practice can cause performance issues

    const setOrganisation = (orgId: string) => {
        setOrgID(orgId);
        setOrganisationDefault(orgId);
        sessionStorage.orgID = orgId;
        isOrgSelected(true);
    };

    useEffect(() => {
        const start = new Date().getTime();
        const getUserOrganisationIds = async () => {
            //TODO vérifier si le call est fait tout de même par connexion Echo
            try {
                const usersApi = await createUsersApi(authInstance!);
                const orgIdsResult = await usersApi.getUserOrganisationIdsByToken(getToken()!);
                if (orgIdsResult && orgIdsResult.data) {
                    const orgIds = orgIdsResult.data.organisationIds;
                    if (orgIds.length > 0) {
                        //  setOrgID(orgIds[0]);
                        setOrganisationDefault(orgIds[0]);
                    }
                    if (orgIds.length === 1) {
                        setOrganisation(orgIds[0]);
                    }
                }
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - start,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.error('Error get organisation ids by token', err);
                setErrorContent(err);
                setError(true);
            }
        };
        if (isLoggedIn()) {
            if (receivedDataContent?.orgId) {
                setOrganisation(receivedDataContent?.orgId);
            } else {
                getUserOrganisationIds();
            }
        }
    }, [
        receivedDataContent?.orgId,
        authInstance,
        currentUserId,
        getToken,
        isLoggedIn,
        isOrgSelected,
        setError,
        setErrorContent,
        setOrgID,
        setOrganisationDefault,
    ]);

    useEffect(() => {
        const start = new Date().getTime();
        const getImpersonatorData = async () => {
            try {
                const usersApi = await createUsersApi(authInstance!);
                const userResult = await usersApi.getUserByToken(getToken()!, organisationDefault!);
                if (userResult && userResult.data) {
                    setImpersonatorName(userResult.data.name!);
                    if (!sessionStorage.currentUserId) {
                        setCurrentUserId(userResult.data.id!);
                        sessionStorage.currentUserId = userResult.data.id;
                    }
                    localStorage.setItem('emailUserAuthentificated', userResult.data.email ?? '');

                    setAccountId(userResult.data.id!);
                    sessionStorage.accountId = userResult.data.id;
                    setPath(ViewOrganization);
                }
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - start,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.error('Error get impersonator data', err);
                setErrorContent(err);
                setError(true);
            }
        };
        if (organisationDefault && isLoggedIn()) {
            getImpersonatorData();
        }
    }, [
        organisationDefault,
        setCurrentUserId,
        setError,
        setErrorContent,
        setImpersonatorName,
        setPath,
        isLoggedIn,
        setAccountId,
        navigate,
        authInstance,
        getToken,
        currentUserId,
    ]);

    useEffect(() => {
        const handleNavigation = async () => {
            if (isLoggedIn()) {
                if (acceptedEula) {
                    const hasExternalPath = location.pathname.includes(ExternalUrlPath);

                    if (
                        location.pathname === ViewEulaAgreement ||
                        location.pathname === ViewLogin ||
                        location.pathname === ViewOrganization ||
                        hasExternalPath
                    ) {
                        let showDefaultView = true;

                        if (
                            hasExternalPath &&
                            receivedDataContent?.recipientEmail &&
                            receivedDataContent.recipientEmail.length > 0
                        ) {
                            try {
                                const usersApi = await createUsersApi(authInstance);
                                const user = await usersApi.getUserSummaryByEmail(
                                    receivedDataContent.recipientEmail,
                                    orgID,
                                );

                                await createOrLoadConversation(user.data.id!);
                                showDefaultView = false; //The user will be redirected to the conversation, so skip the default view
                            } catch (err) {
                                // TODO: Add logic to detect if the user is not found, if so then we display a warning
                                // to the user otherwise for all other error type then we show the generic error
                                console.error('Error fetching user by email:', error);
                            }
                        }

                        if (showDefaultView) {
                            setPath(ViewDefault);
                            navigate(ViewDefault); // Navigate to default view of the application
                        }
                    }
                } else if (acceptedEula === false) {
                    setPath(ViewEulaAgreement);
                    navigate(ViewEulaAgreement);
                }
            }
        };

        handleNavigation();
    }, [acceptedEula, receivedDataContent?.recipientEmail, navigate]);

    useEffect(() => {
        const start = new Date().getTime();
        const getEulaAttribute = async () => {
            try {
                const usersApi = await createUsersApi(authInstance!);
                const attributesData = await usersApi.getAttributes(orgID!, WEB_SERVICE_APPLICATION_ID, accountId);
                const parsedAttributes = JSON.parse(attributesData.data.attributes!);

                setAcceptedEula(parsedAttributes.accepted_eula ?? false);
                if (parsedAttributes.accepted_eula) {
                    setSecurityActive(parsedAttributes.accepted_eula);
                    sessionStorage.secActive = parsedAttributes.accepted_eula;
                }
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - start,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.error('Error get impersonator data', err);
                setErrorContent(err);
                setError(true);
            }
        };
        if (orgSelected && orgID && accountId) {
            getEulaAttribute();
        }
    }, [
        orgID,
        orgSelected,
        accountId,
        authInstance,
        setSecurityActive,
        currentUserId,
        setErrorContent,
        setError,
        setAcceptedEula,
    ]);

    useEffect(() => {
        if (isLoggedIn()) {
            setGoToLogin(true); //CHanger nom de variable
            setShowBell(true);
        }

        const pathname = ourLocation.pathname;
        const findPathname = (term: string) => {
            if (pathname.includes(term)) {
                return pathname;
            }
        };

        switch (pathname) {
            case findPathname(ViewChat):
                setCreateConversation(true);
                setCreateGroup(true);
                setInfoGroup(true);
                setContextTitle(currentUserName ? currentUserName : impersonatorName);
                setShowNavIcon(true);
                break;
            case findPathname(ViewOrganization):
                setCreateConversation(false);
                setCreateGroup(false);
                setInfoGroup(false);
                setContextTitle(currentUserName ? currentUserName : impersonatorName);
                setShowNavIcon(false);
                break;
            case ViewCreateConversation:
                const search = t('search.title');
                // setCreateConversation(false);
                setCreateGroup(true);
                setInfoGroup(false);
                setContextTitle(search);
                setShowNavIcon(true);
                break;
            case ViewCreateGroup:
                const groupCreation = t('groupCreation', { ns: 'user' });
                setCreateConversation(true);
                setCreateGroup(true);
                setInfoGroup(false);
                setContextTitle(groupCreation);
                setShowNavIcon(true);
                break;
            case ViewLogin:
                setCreateConversation(false);
                setCreateGroup(false);
                setInfoGroup(false);
                setContextTitle(currentUserName ? currentUserName : impersonatorName);
                setShowNavIcon(false);
                break;
            case ViewEulaAgreement:
                setCreateConversation(false);
                setCreateGroup(false);
                setInfoGroup(false);
                setContextTitle('');
                setShowNavIcon(false);
                break;
            default:
                break;
        }
    }, [
        currentUserName,
        getAlias,
        goToLogin,
        impersonatorName,
        isLoggedIn,
        isUser,
        ourLocation.pathname,
        setShowNavIcon,
        t,
    ]);

    // async removed from this function below
    const onInfoClick = () => {
        // setCreateConversation(false);
        setInfoGroup(false);
        // setCreateGroup(false);
    };

    const onCreateConversationClick = () => {
        // setCreateConversation(false);
        setInfoGroup(false);
        setCreateGroup(true);
    };

    const onCreateGroupClick = () => {
        // setCreateConversation(false);
        setInfoGroup(false);
        // setCreateGroup(false);
    };

    const onClose = () => {
        setError(false);
        setIsLoggingOut(false);
    };

    const logout = useCallback(async () => {
        cleanupOnLogout();
        setSecurityActive(false);
        setOrganisationDefault(undefined);

        await deleteNotificationDevice(getDeviceToken(), orgID, currentUserId, authInstance);

        logoutAuthProvider({ redirectUri: window.location.origin });
    }, [authInstance, currentUserId, logoutAuthProvider, orgID, setOrganisationDefault, setSecurityActive]);

    const onConfirmLogout = () => {
        logout();
    };

    useEffect(() => {
        if (idleTimerInfo.remaining === 0 && isLoggedIn()) {
            logout();
        }
    }, [idleTimerInfo.remaining, isLoggedIn, logout]);

    const cleanupOnLogout = () => {
        sessionStorage.removeItem('accountId');
        sessionStorage.removeItem('impersonatorName');
        sessionStorage.removeItem('orgID');
        sessionStorage.removeItem('secActive');
        sessionStorage.removeItem('currentUserId');
        localStorage.removeItem('emailUserAuthentificated');
    };

    useEffect(() => {
        //TODO ajouter le onAuthError + assigner dans App.tsx

        auth.onAuthLogout = () => {
            cleanupOnLogout();
        };

        if (!isLoggedIn() && lastVirtualTeamId && lastVirtualTeamOrgId) {
            updateNotificationDevice(lastVirtualTeamId, lastVirtualTeamOrgId, authInstance);
        }

        // const logoutBeforeUnload = (ev: any) => {
        //     ev.preventDefault();
        //     deleteUserOrVirtualTeamDevice();
        //     getFirebaseNotifToken(true);
        // };
        // window.addEventListener('beforeunload', deleteBeforeUnload);
        // return () => window.removeEventListener('beforeunload', deleteBeforeUnload);
    }, [authInstance, isLoggedIn, lastVirtualTeamId, lastVirtualTeamOrgId]);

    const logoutStatus = isLoggedIn();
    useEffect(() => {
        if (!logoutStatus) {
            isOrgSelected(false);
        }
    }, [isOrgSelected, logoutStatus]);

    useEffect(() => {
        if (
            orgID &&
            logoutStatus &&
            location.pathname !== ViewOrganization &&
            location.pathname !== ViewLogin &&
            location.pathname !== ViewEulaAgreement
        ) {
            isOrgSelected(true);
        }
    }, [isOrgSelected, location.pathname, logoutStatus, orgID]);

    useEffect(() => {
        if (isLoggedIn() && authInstance) {
            const minValidity: number | undefined = getSafeTokenDurationBeforeRefresh();
            const safeTokenDurationBeforeRefresh: number = settings.tokenRefreshMarginBeforeExpiration;
            const validateTokenDuration = 60000;
            try {
                if (safeTokenDurationBeforeRefresh) {
                    const interval = setInterval(() => {
                        const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
                        const tokenExpiryTime = authInstance.tokenParsed.exp; // Token expiration time in seconds

                        // Check if the token has less than minimal time left before it expires
                        const timeLeft = tokenExpiryTime - currentTime;

                        if (timeLeft <= safeTokenDurationBeforeRefresh) {
                            authInstance
                                ?.updateToken(safeTokenDurationBeforeRefresh)
                                .then()
                                .catch(() => logout());
                        }
                    }, validateTokenDuration);
                    return () => {
                        clearInterval(interval);
                    };
                }
            } catch {
                logout();
            }
        }
    }, [authInstance, getSafeTokenDurationBeforeRefresh, isLoggedIn, logout]);

    return (
        <>
            {/*Il faudra faire changer alias par getAlias qui lui va chercher le localStorage
             qu'on ajoute lorsque le get est effectuer la première fois qu'on fait le get par alias */}
            {idleTimerInfo.warningLogout && <TimeoutMessage onClose={idleTimerInfo.activate} />}
            {error && <ErrorMessage onClose={onClose} />}

            {isLoggingOut && (
                <NotificationMessage
                    onClose={onClose}
                    onConfirmYes={onConfirmLogout}
                    title={t('confirmation', { ns: 'validation' })}
                    textContent={t('areYouSureQuit', { ns: 'validation' })}
                />
            )}
            {
                <>
                    <ConversationContext.Provider
                        value={{
                            clickedConversation,
                            setClickedConversation,
                            conversationId,
                            setCurrentConversationId: setConversationId,
                            isGroup,
                            setIsGroup,
                        }}>
                        {goToLogin && (
                            <NavbarIdtextoUIContainer
                                authInstance={authInstance!}
                                canCreateConversation={createConversation}
                                canCreateGroup={createGroup}
                                canGetInfoGroup={infoGroup}
                                currentOrganisationId={orgID}
                                hasUnreadMessage={hasUnreadMessage}
                                isGroup={isGroup}
                                isLoggedIn={isLoggedIn()!}
                                organisationConfigured={orgSelected}
                                showBell={showBell}
                                showNavIcon={showNavIcon}
                                conversationId={conversationId}
                                onCreateConversationClick={onCreateConversationClick}
                                onCreateGroupClick={onCreateGroupClick}
                                onInfoClick={onInfoClick}
                                accountId={accountId}
                                securityActive={securityActive}
                                currentUserId={currentUserId}
                                setAlias={setAlias}
                                setCurrentUserId={setCurrentUserId}
                                setCurrentUserName={setCurrentUserName}
                                setLastVirtualTeam={setLastVirtualTeam}
                                setLastVirtualTeamOrganizationId={setLastVirtualTeamOrganizationId}
                                setConversationId={setConversationId}
                                showBackButton={false}
                                setIsGroup={setIsGroup}
                                setIsConversationActive={setIsConversationActive}
                                logout={logout}
                                impersonatorName={impersonatorName}
                                authContext={auth}
                            />
                        )}
                        {/* //TODO : Changer les routes selon si USER ou TEAM. Chat devrait avoir sa route. Change to lowercase for path */}

                        {queryStateNavbarGetOrgById?.fetchStatus === 'fetching' &&
                        queryStateNavbarGetOrgById?.status === 'loading' ? (
                            <GlobalSpinner />
                        ) : (
                            <MainRouter path={path} />
                        )}
                    </ConversationContext.Provider>
                </>
            }
        </>
    );
}

export default App;
