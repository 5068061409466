/* eslint-disable max-lines-per-function */
import { useState, useEffect, useRef, useContext, Dispatch, SetStateAction } from 'react';
import Message, { MessageContent } from '../Message/Message';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { ConversationContext } from '../../Contexts/conversationIdContext';
import { useTranslation } from 'react-i18next';
import './messageList.css';
import { IgetAllMessageData, IRecipient } from '../Conversation/IConversation';
import { NotificationsContext } from '../../Contexts/notificationsContext';
import { IMarkMessagesAsReceivedReadResult, markMessagesAsReceivedRead } from '../../common/messageUtil';
import { Dialog } from '@progress/kendo-react-dialogs';
import { InterlocutorType, SearchUIContainer, SearchUsageType } from 'idside-ui';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { createConversationsApi, ApiMsConversations, buildLogsAndPostToIndexedDB, settings, useAuth } from 'idside-core';
import UseUserAttributes from '../../hooks/useUserAttributes';
import { MessageContextMenuActionType, MessageContextMenuActionTypeEnum } from '../commonData';
import useCreateConversation from '../../hooks/useCreateConversation';

type IMessagesListProps = {
    messageList: IgetAllMessageData[];
    pasteInput: boolean;
    copy: (message: MessageContent) => void;
    reply: (e: MessageContent) => void;
    forward: (e: MessageContent) => void;
    newRecipientArray: INewRecipient[] | undefined;
    getConversationName: IgetAllMessageData[] | undefined;
    messageImpersonatorName: any;
    setMessageList: Dispatch<SetStateAction<IgetAllMessageData[]>>;
    impersonatorIdList: string[] | undefined;
    hideScrollBottom: boolean;
    conversations: ApiMsConversations.ConversationResponse[];
    setConversations: Dispatch<SetStateAction<ApiMsConversations.ConversationResponse[]>>;
    noRecentMessages: boolean;
    sendMessage: (conversationId?: string) => Promise<void>;
    setActionType: Dispatch<SetStateAction<MessageContextMenuActionType>>;
    actionType: MessageContextMenuActionType;
};

interface INewRecipient {
    key: string;
    id: string;
}

const MessagesList = ({
    messageList,
    copy,
    reply,
    forward,
    getConversationName,
    setMessageList,
    messageImpersonatorName,
    impersonatorIdList,
    hideScrollBottom,
    setConversations,
    noRecentMessages,
    sendMessage,
    setActionType,
    actionType,
}: IMessagesListProps) => {
    const [t] = useTranslation(['message', 'common', 'validation']);
    const { conversationId, setCurrentConversationId } = useContext(ConversationContext);
    const { orgID: currentOrganisationId, accountId, setError, setErrorContent } = useContext(GlobalInfoContext);
    const [lastMessageId, setLastMessageId] = useState<string>('');
    const messagesEndRef = useRef<null | HTMLDivElement>(null);
    const { currentUserId, orgID } = useContext(GlobalInfoContext);
    const [lastMessageClicked, setLastMessageClicked] = useState(true);
    const [clicked, setClickedButton] = useState<boolean>(false);
    const { sethasReadNewMessage } = useContext(NotificationsContext);
    const [displaySearchModal, setDisplaySearchModal] = useState<boolean>(false);
    const auth = useAuth();
    const { getToken, authInstance } = auth;
    const { createOrLoadConversationByInterlocutorType, callSendMessage, setCallSendMessage } = useCreateConversation();

    const scrollToBottom = () => {
        if (messagesEndRef.current !== null) {
            messagesEndRef.current.scrollIntoView();
        }
    };

    const filterUnReadedMessages = () => {
        return messageList.filter((message) => {
            const currentRecepientUser = message.recipients.find(
                (recipient: IRecipient) => recipient.id === currentUserId,
            );
            return currentRecepientUser?.readDate === undefined;
        });
    };

    const conversationSelectorUpdateOnClick = (messageId: string) => {
        const unreadedMessages = filterUnReadedMessages();
        if (
            unreadedMessages.length === 1 ||
            (messageList[messageList.length - 1].id === messageId && lastMessageClicked)
        ) {
            setConversations((prevConversations: ApiMsConversations.ConversationResponse[]) =>
                prevConversations.map((conv: ApiMsConversations.ConversationResponse) =>
                    conv.id === conversationId ? { ...conv, allMessagesReaded: true } : conv,
                ),
            );
        }
        if (messageList[messageList.length - 1].id === messageId) setLastMessageClicked(false);
    };

    //Ajouter fonctionnalité qui va venir appliquer le readDate lors du clique sur un message en particulier, ensuite venir appliquer le readDate sur tout les messages du haut
    //si le readDate n'est pas vide
    const putReadMessage = async (messageId: string) => {
        const clickedMessage = messageList.find((message) => message.id === messageId);

        if (clickedMessage?.createdDate) {
            let markMessagesAsReceivedReadResult: IMarkMessagesAsReceivedReadResult = await markMessagesAsReceivedRead(
                conversationId,
                currentUserId,
                orgID,
                clickedMessage?.createdDate,
                messageList,
                true,
                false,
                authInstance,
            );

            setMessageList(markMessagesAsReceivedReadResult.messages);

            setClickedButton(true);

            if (markMessagesAsReceivedReadResult.needToUpdateReadDate) {
                conversationSelectorUpdateOnClick(messageId);
                sethasReadNewMessage(true);
            }
        }
    };

    const getRefElementByIdMessaage = (idMessage: any): any => {
        return messageList.find((msg) => msg.id === idMessage)?.messageRef;
    };

    const OnScollToInitialMessage = (idMessage: any) => {
        const element = getRefElementByIdMessaage(idMessage)?.current;
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        const parentElement = element?.parentElement;
        if (parentElement) {
            // TODO: use fade-in fade-out instead of add/remove class on element
            parentElement.className = 'original-message-animation-add-red';
            setTimeout(() => {
                parentElement.className = 'original-message-animation-delete-red ';
            }, 2000);
        }
    };

    useEffect(() => {
        const executeAsyncSendMessage = async () => {
            await sendMessage();
        };

        if (actionType === MessageContextMenuActionTypeEnum.Forward) {
            executeAsyncSendMessage();
            setDisplaySearchModal(false);
            setActionType(MessageContextMenuActionTypeEnum.NoAction);
            setCallSendMessage(false);
        }
    }, [callSendMessage]);

    useEffect(() => {
        if (messageList.length > 0) {
            setLastMessageId(messageList[messageList.length - 1].id);
        } else {
            setLastMessageId('');
        }
    }, [messageList]);

    useEffect(() => {
        if (hideScrollBottom) {
            setLastMessageClicked(true);
            if (messageList.length > 0 && messageList[messageList.length - 1].id !== lastMessageId) {
                scrollToBottom();
            }
            return () => {
                scrollToBottom();
            };
        }
    }, [lastMessageId]);

    return (
        <>
            {messageList.length > 0 &&
                conversationId &&
                messageList.map((message: any, index: number) => {
                    return (
                        <Message
                            key={message.id}
                            onCopy={copy}
                            onReply={reply}
                            onForward={forward}
                            messageData={message}
                            isGroupConversation={getConversationName ? true : false}
                            readMessage={() => putReadMessage(message.id)}
                            messageImpersonatorName={messageImpersonatorName}
                            impersonatorIdList={impersonatorIdList}
                            clicked={clicked}
                            setClickedButton={setClickedButton}
                            OnScollToInitialMessage={OnScollToInitialMessage}
                            messageRef={message.messageRef}
                            setDisplaySearchModal={setDisplaySearchModal}
                        />
                    );
                })}
            {messageList.length === 0 && conversationId && noRecentMessages === true && (
                <div className="noMessage">{t('noMessage', { ns: 'message' })}</div>
            )}
            <div ref={messagesEndRef}></div>
            {displaySearchModal && (
                <Dialog
                    title={t('search.title', { ns: 'common' })}
                    onClose={() => {
                        setActionType(MessageContextMenuActionTypeEnum.NoAction);
                        setDisplaySearchModal(false);
                    }}
                    minWidth={'80%'}
                    height={'80%'}>
                    <SearchUIContainer
                        funcForSelectedUserId={createOrLoadConversationByInterlocutorType}
                        filteredList={[]}
                        currentOrganisationId={currentOrganisationId}
                        setError={setError}
                        setErrorContent={setErrorContent}
                        currentUserId={currentUserId}
                        authInstance={authInstance!}
                        useUserAttributes={UseUserAttributes}
                        includeConversations={true}
                        searchUsageType={SearchUsageType.UsageForForward}
                        authContext={auth}
                    />
                </Dialog>
            )}

            {/* TODO: typing animation */}
            {/* <>
                <div className="typing-chat-bubble">
                    <div className="typing">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </div>
            </> */}
        </>
    );
};

export default MessagesList;
