import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Chat from './pages/Chat/Chat';
import CreateConversation from './pages/CreateConversation/CreateConversation';
import Eula from './pages/Eula/Eula';
import Group from './pages/Group/Group';
import StandardLogin from './pages/Login/Login';
import OrganizationSelector from './pages/OrganizationSelector/OrganizationSelector';
import RenderOnAuthenticated from './component/global/RenderOnAuthentificated';
import { ViewChat, ViewCreateConversation, ViewCreateGroup, ViewLogin } from './common/ViewPath';

interface IMainRouterProps {
    path: string;
}

function MainRouter({ path }: IMainRouterProps) {
    return (
        <Routes>
            <Route path="/" element={<StandardLogin path={path} />} />
            <Route path={ViewLogin} element={<StandardLogin path={path} />} />
            <Route
                path="/select-organization"
                element={
                    <RenderOnAuthenticated>
                        <OrganizationSelector />
                    </RenderOnAuthenticated>
                }
            />
            <Route
                path="/eula-agreement"
                element={
                    <RenderOnAuthenticated>
                        <Eula />
                    </RenderOnAuthenticated>
                }
            />
            <Route
                path={ViewCreateConversation}
                element={
                    <RenderOnAuthenticated>
                        <CreateConversation />
                    </RenderOnAuthenticated>
                }
            />
            <Route
                path={ViewCreateGroup}
                element={
                    <RenderOnAuthenticated>
                        <Group />
                    </RenderOnAuthenticated>
                }
            />
            <Route
                path={`${ViewChat}/:organisationId/:conversationIdParam`}
                element={
                    <RenderOnAuthenticated>
                        <Chat />
                    </RenderOnAuthenticated>
                }
            />
            <Route
                path={ViewChat}
                element={
                    <RenderOnAuthenticated>
                        <Chat />
                    </RenderOnAuthenticated>
                }
            />
        </Routes>
    );
}

export default MainRouter;
