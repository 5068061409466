/* eslint-disable max-lines-per-function */
import { useTranslation } from 'react-i18next';

interface IInvalidMessageLabelProps {
    showIcon?: boolean;
    darkIcon?: boolean;
    showText?: boolean;
};

export default function InvalidMessageLabel(props : IInvalidMessageLabelProps) {
    const [t] = useTranslation();

    return (
        <>
            {props.showIcon !== false && (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    width="30px"
                    fill={props.darkIcon !== false ? "black" : "white"}
                    style={{padding: '1px'}}
                    viewBox="0 0 512 512">
                    <path d="M256 480c123.7 0 224-100.3 224-224S379.7 32 256 32 32 132.3 32 256s100.3 224 224 224m-32-352h64v160h-64zm0 256v-64h64v64z" />
                </svg>
            )}
            {props.showIcon !== false && props.showText !== false && (
                <>
                    &nbsp;
                </>
            )}
            {props.showText !== false && (
                <i>
                    {t('errorAffichage', { ns: 'error' })}
                </i>
            )}
        </>
    )
}
