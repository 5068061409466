import { useContext } from 'react';
import { WEB_SERVICE_APPLICATION_ID, buildLogsAndPostToIndexedDB, createUsersApi, settings, useAuth } from 'idside-core';
import { GlobalInfoContext } from '../Contexts/globalInfoContext';

const UseUserAttributes = (orgID: string, token: string = ''): [(idUser: string) => Promise<any>] => {
    const { setError, setErrorContent } = useContext(GlobalInfoContext);
    const { authInstance } = useAuth();

    const fetchGetPersonalData = async (idUser: string) => {
        const start = new Date().getTime();
        try {
            const usersApi = await createUsersApi(authInstance!, orgID);
            const result = await usersApi.getAttributes(
                orgID,
                WEB_SERVICE_APPLICATION_ID,
                idUser,
            );
            if (result.data.attributes) {
                return JSON.parse(result.data.attributes);
            }
        } catch (err: any) {
            await buildLogsAndPostToIndexedDB(
                err.message,
                err.stack,
                new Date().getTime() - start,
                settings.appId,
                window.location.origin,
                err.config.headers.trackingId,
                err.config[`axios-retry`].retries,
                authInstance?.sessionId!,
                idUser,
                err.config.headers.deviceId,
                err.config.headers.organisationId,
                err.config.headers.provider,
                settings.dbName,
                settings.storeIdTexto,
            );
            setError(true);
            setErrorContent(err);
        }
    };
    return [fetchGetPersonalData];
};
export default UseUserAttributes;
