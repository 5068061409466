import { createMessagesApi, ApiMsConversations, EchoAuth } from 'idside-core';
import { IgetAllMessageData, IRecipient } from '../component/Conversation/IConversation';
import Keycloak from 'keycloak-js';
import Resizer from 'react-image-file-resizer';
import { Document, MessageContent } from '../component/Message/Message';
import { MessageActionType, MessageContextMenuActionType, MessageContextMenuActionTypeEnum, MimeTypeEnum } from '../component/commonData';

import { Buffer } from 'buffer';
import { IMessage } from '../component/Message/Message';

/**
 * Fonction utilitaire pour vérifier si les champ nécessaire pour un reply ou forward sont bien dans l'objet
 * @param obj L'objet ajouter pour en faire la vérification
 * @returns si l'objet est de type message avec tout les champs
 */
export const hasAllMessageFields = (obj: any): obj is IMessage => {
    const requiredFields: (keyof IMessage)[] = [
        'id',
        'createdDate',
        'auteurId',
        'impersonatorId',
        'text',
        'conversationId',
        'auteur',
    ];

    // Vérifie que chaque champ requis est présent dans l'objet
    const hasRequiredFields = requiredFields.every((field) => field in obj);

    return hasRequiredFields;
};

export const resizeFile = (file: any) =>
    new Promise((resolve: (value: string) => void) => {
        Resizer.imageFileResizer(
            file,
            128,
            128,
            'JPEG',
            70,
            0,
            (uri) => {
                resolve(String(uri));
            },
            'base64',
        );
    });

export const preventPropagationToParent = (e: any) => {
    e.stopPropagation();
};

export function removeDuplicatesMessageList(arr: IgetAllMessageData[]) {
    const seen = new Set();
    const filteredArr = arr.filter((el) => {
        const duplicate = seen.has(el.id);
        seen.add(el.id);
        return !duplicate;
    });
    return filteredArr;
}

export interface IMarkMessagesAsReceivedReadResult {
    needToUpdateReceivedDate: boolean;
    needToUpdateReadDate: boolean;
    messages: IgetAllMessageData[];
}

export interface IMinimalForwardedInfo {
    id: string | undefined;
    previousMessageId?: string | undefined;
}
export interface IMinimalForwardedCopiedInfo {
    id: string | undefined;
    text: string | undefined;
}

export async function markMessagesAsReceivedRead(
    conversationId: string,
    currentUserId: string,
    organisationId: string,
    equalAndBeforeDateAsString: string,
    messages: IgetAllMessageData[],
    markMessagesAsRead: boolean,
    hasNewMessages: boolean,
    authInstance: Keycloak | EchoAuth | null,
): Promise<IMarkMessagesAsReceivedReadResult> {
    let needToUpdateReceivedDate: boolean = false;
    let needToUpdateReadDate: boolean = false;

    if (conversationId && equalAndBeforeDateAsString && messages?.length > 0) {
        const equalAndBeforeDate: Date = new Date(equalAndBeforeDateAsString);
        let messageStatus: ApiMsConversations.MessageStatusUpdateRequest = {
            conversationId: conversationId,
            equalAndBeforeDate: equalAndBeforeDateAsString, //TODO: Fix precision error for date
        };

        messages.find((message: IgetAllMessageData) => {
            if (new Date(message.createdDate) <= equalAndBeforeDate) {
                const currentRecipientUser = message.recipients.find(
                    (recipient: IRecipient) => recipient.id === currentUserId,
                );

                if (markMessagesAsRead) {
                    if (currentRecipientUser?.readDate === undefined) {
                        //We must set all messages as read (e.i. when clicking on the conversation)
                        needToUpdateReceivedDate = true;
                        needToUpdateReadDate = true;
                        return true;
                    }
                } else if (hasNewMessages) {
                    if (currentRecipientUser?.receivedDate === undefined) {
                        //We have new message to be marked as received
                        needToUpdateReceivedDate = true;
                        return true;
                    }
                }
            }

            return false;
        });

        if (needToUpdateReceivedDate) {
            messageStatus.isReceived = true;
        }

        if (needToUpdateReadDate) {
            messageStatus.isRead = true;
        }

        if (needToUpdateReceivedDate || needToUpdateReadDate) {
            //We must not pass an organisationId in the header for updateMessage so setting none
            const messagesApi = await createMessagesApi(authInstance!);
            await messagesApi.updateMessageStatus(organisationId, currentUserId, messageStatus);

            //Pre-update the messages until we receive the notifications

            messages.forEach((message: IgetAllMessageData) => {
                if (new Date(message.createdDate) <= equalAndBeforeDate) {
                    const currentRecipientUser = message.recipients.find(
                        (recipient: IRecipient) => recipient.id === currentUserId,
                    );

                    if (currentRecipientUser) {
                        //Set a local date time for now simply to have a received or read date until the real
                        //date is sent back grom the backend. The temp may be offset but this has no impact
                        //since the date is not shown to the user.
                        const dateTimeNow: string = new Date(Date.now()).toISOString();
                        if (needToUpdateReceivedDate && !currentRecipientUser.receivedDate) {
                            currentRecipientUser.receivedDate = dateTimeNow;
                        }
                        if (needToUpdateReadDate && !currentRecipientUser.readDate) {
                            message.currentUserReadDate = dateTimeNow;
                            currentRecipientUser.readDate = dateTimeNow;
                        }
                    }
                }
            });
        }
    }

    return {
        messages: messages,
        needToUpdateReceivedDate: needToUpdateReceivedDate,
        needToUpdateReadDate: needToUpdateReadDate,
    };
}

export const getDocumentsFromMessage = (message: IgetAllMessageData): any[] => {
    return message.documents;
};

export function extractMinimalForwardMessageInfo(
    messageData: MessageContent,
    forwardMessageId: string | undefined,
): Document[] {
    return messageData.documents
        .filter(
            (doc) =>
                doc.mimeType !== MimeTypeEnum.REPLY &&
                doc.mimeType !== MimeTypeEnum.FORWARD &&
                doc.referenceMessageId === forwardMessageId, // if referenceMessageId === undefined then it's a first forward otherwise it's a forward of forward
        )
        .map((doc) => {
            // Adding a data field with the base64 encoded id
            const encodedMessageId = Buffer.from(messageData.id, 'utf-8').toString('base64');
            return {
                ...doc,
                data: encodedMessageId,
            };
        });
}
export function extractForwardDocument(messageData: MessageContent, forwardMessageId: string | undefined): Document[] {
    return messageData.documents.filter(
        (doc) =>
            doc.mimeType !== MimeTypeEnum.REPLY &&
            doc.mimeType !== MimeTypeEnum.FORWARD &&
            doc.referenceMessageId === forwardMessageId, // if referenceMessageId === undefined then it's a first forward otherwise it's a forward of forward
    );
}

// function used to extract only the id and text of a forwarded message
export function extractForwardedMessageIdAndText(messageData: MessageContent): IMinimalForwardedCopiedInfo | undefined {
    let forwardDocument = messageData.documents.find(
        (elt) => elt.mimeType === MimeTypeEnum.FORWARD && elt.referenceMessageId === undefined,
    );
    if (forwardDocument && forwardDocument.data) {
        return JSON.parse(Buffer.from(forwardDocument.data, 'base64').toString());
    }
    return undefined;
}

export function createDeepCopyOfMessageContent(
    messageContent: MessageContent,
    actionType?: MessageActionType,
): MessageContent {
    let deepCopyMessageContent: MessageContent = {
        accountId: messageContent.accountId,
        authorId: messageContent.authorId,
        createdDate: messageContent.createdDate,
        currentUserReadDate: messageContent.currentUserReadDate,
        decrypted: messageContent.decrypted,
        documents: [],
        id: messageContent.id,
        impersonatorId: messageContent.impersonatorId,
        impersonatorName: messageContent.impersonatorName,
        interlocutorReadDate: messageContent.interlocutorReadDate,
        interlocutorReceivedDate: messageContent.interlocutorReceivedDate,
        message: messageContent.message,
        messageAutorFullName: messageContent.messageAutorFullName,
        readCount: messageContent.readCount,
        recipients: [],
        user: messageContent.user,
    };

    messageContent.documents.forEach((doc: Document) => {
        deepCopyMessageContent.documents.push({
            id: doc.id,
            mimeType: doc.mimeType,
            thumbnail: doc.thumbnail,
            data: doc.data,
            filename: doc.filename,
            orderNumber: doc.orderNumber,
            size: doc.size,
            referenceMessageId: doc.referenceMessageId,
            copyInitiator:
                !actionType || actionType === MessageContextMenuActionTypeEnum.Copy
                    ? doc.referenceMessageId
                    : undefined, //No need to copy this info when forwarding or replying, it is only a temp info when copy-pasting or doing a full deep copy with no context
        });
    });

    messageContent.recipients.forEach((recipient: IRecipient) => {
        deepCopyMessageContent.recipients.push({
            id: recipient.id,
            key: recipient.key,
            readDate: recipient.readDate,
            receivedDate: recipient.receivedDate,
            isAlertActive: recipient.isAlertActive,
        });
    });

    return deepCopyMessageContent;
}

export function addReplyOrForwardDataToDocumentList(    
    actionType: MessageContextMenuActionType,
    originalForwardMessageInfo: IMinimalForwardedCopiedInfo | undefined,
    originMessageInfo: MessageContent | undefined,
    downloadFileData: Document[] | undefined,
    replyForwardDocumentList: Document[]) {
    if (
        actionType === MessageContextMenuActionTypeEnum.Reply ||
        actionType === MessageContextMenuActionTypeEnum.Forward
    ) {
        const mimeType =
            actionType === MessageContextMenuActionTypeEnum.Reply ? MimeTypeEnum.REPLY : MimeTypeEnum.FORWARD;

        let data: IMinimalForwardedInfo;
        if (originalForwardMessageInfo !== undefined) {
            // forward of forward
            data = {
                id: originalForwardMessageInfo?.id,
                previousMessageId: originMessageInfo?.id
            };
        } else {
            data = {
                id: originMessageInfo?.id,
            };
        }

        replyForwardDocumentList.push(
            Object.assign(
                {},
                {
                    orderNumber: 1,
                    mimeType: mimeType,
                    data: Buffer.from(JSON.stringify(data)).toString('base64'),
                },
            ),
        );
        downloadFileData?.forEach((doc) => {
            replyForwardDocumentList.push(doc);
        });
    }
}