/* eslint-disable max-lines-per-function */
import { useContext } from 'react';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import './createConversation.css';
import { useAuth } from 'idside-core';
import { SearchUIContainer, SearchUsageType } from 'idside-ui';
import UseUserAttributes from '../../hooks/useUserAttributes';
import useCreateConversation from '../../hooks/useCreateConversation';

const CreateConversation = () => {
    const { orgID: currentOrganisationId, currentUserId, setError, setErrorContent } = useContext(GlobalInfoContext);
    const auth = useAuth();
    const { authInstance } = auth;
    const { createOrLoadConversation } = useCreateConversation();

    return (
        <>
            <div>
                <SearchUIContainer
                    funcForSelectedUserId={createOrLoadConversation}
                    filteredList={[]}
                    currentOrganisationId={currentOrganisationId}
                    setError={setError}
                    setErrorContent={setErrorContent}
                    currentUserId={currentUserId}
                    authInstance={authInstance!}
                    useUserAttributes={UseUserAttributes}
                    searchUsageType={SearchUsageType.UsageForCreateConversation}
                    authContext={auth}
                />
            </div>
        </>
    );
};

export default CreateConversation;
