/* eslint-disable max-lines-per-function */
import { useContext, useState } from 'react';
import { buildLogsAndPostToIndexedDB, createConversationsApi, settings, useAuth } from 'idside-core';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router';
import { GlobalInfoContext } from '../Contexts/globalInfoContext';
import { InterlocutorType } from 'idside-ui';
import { ConversationContext } from '../Contexts/conversationIdContext';

export interface IIdleTimerInfo {
    remaining: number;
    warningLogout: boolean;
    activate: () => boolean;
}

const useCreateConversation = () => {
    const auth = useAuth();
    const { getToken, authInstance } = auth;
    const navigate = useNavigate();
    const { setCurrentConversationId } = useContext(ConversationContext);
    const [callSendMessage, setCallSendMessage] = useState(false);
    const { orgID: currentOrganisationId, currentUserId, setError, setErrorContent } = useContext(GlobalInfoContext);

    const showConversation = async (conversationId: string) => {
        const startShowConv = new Date().getTime();
        try {
            const conversationsCreateApi = await createConversationsApi(authInstance!, currentOrganisationId);
            await conversationsCreateApi.editConversationV2(
                conversationId,
                {
                    editedRecipients: [
                        {
                            id: currentUserId,
                            masked: false,
                        },
                    ],
                },
                {
                    headers: {
                        currentUserId: currentUserId,
                    },
                },
            );
        } catch (err: any) {
            await buildLogsAndPostToIndexedDB(
                err.message,
                err.stack,
                new Date().getTime() - startShowConv,
                settings.appId,
                window.location.origin,
                err.config.headers.trackingId,
                err.config[`axios-retry`].retries,
                authInstance?.sessionId!,
                currentUserId,
                err.config.headers.deviceId,
                err.config.headers.organisationId,
                err.config.headers.provider,
                settings.dbName,
                settings.storeIdTexto,
            );
            console.log(err);
        }
    };

    const createOrLoadConversationInternal = async (
        interLocutorId: string,
        interlocutorType: InterlocutorType | undefined,
        updateConversationId: boolean,
    ) => {
        const startCreateOrLoadConv = new Date().getTime();

        if (getToken())
            try {
                const conversationsByRecipientApi = await createConversationsApi(authInstance!, currentOrganisationId);
                const { data }: AxiosResponse = await conversationsByRecipientApi.getConversationsByRecipientId(
                    currentOrganisationId,
                    currentUserId,
                );

                let conversationFound: any;

                if (interlocutorType === InterlocutorType.GroupDiscussion) {
                    conversationFound = data.find((conversation: any) => conversation.id === interLocutorId);
                } else {
                    conversationFound = data.find((conversation: any) => {
                        return (
                            conversation.name == null &&
                            conversation.recipients.find((recipient: any) => {
                                return recipient.id === interLocutorId;
                            })
                        );
                    });
                }

                if (conversationFound !== undefined) {
                    await showConversation(conversationFound.id);

                    navigate(`/chat/${currentOrganisationId}/${conversationFound.id}`);

                    if (updateConversationId) {
                        setCurrentConversationId(conversationFound.id!);
                        setCallSendMessage(true);
                    }
                } else {
                    if (getToken()) {
                        const startCreateConv = new Date().getTime();
                        try {
                            const conversationsPostApi = await createConversationsApi(
                                authInstance!,
                                currentOrganisationId,
                            );
                            const { data: postConversationData } = await conversationsPostApi.postConversation(
                                currentOrganisationId,
                                {
                                    creatorId: currentUserId,
                                    //ligne 64 vérifier si le recipients est le bon et non accountId pareil pour ligne 44
                                    recipients: [{ id: currentUserId }, { id: interLocutorId }],
                                },
                            );

                            navigate(`/chat/${currentOrganisationId}/${postConversationData.id}`);

                            if (updateConversationId) {
                                setCurrentConversationId(postConversationData.id!);
                                setCallSendMessage(true);
                            }
                        } catch (err: any) {
                            await buildLogsAndPostToIndexedDB(
                                err.message,
                                err.stack,
                                new Date().getTime() - startCreateConv,
                                settings.appId,
                                window.location.origin,
                                err.config.headers.trackingId,
                                err.config[`axios-retry`].retries,
                                authInstance?.sessionId!,
                                currentUserId,
                                err.config.headers.deviceId,
                                err.config.headers.organisationId,
                                err.config.headers.provider,
                                settings.dbName,
                                settings.storeIdTexto,
                            );
                            console.log(`Error creating conversation with recipient ${interLocutorId}: `, err);
                        }
                    }
                }
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - startCreateOrLoadConv,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    currentUserId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.log(err);
                setError(true);
                setErrorContent(err);
            }
    };

    const createOrLoadConversation = async (interLocutorId: string) => {
        createOrLoadConversationInternal(interLocutorId, undefined, false);
    };

    const createOrLoadConversationByInterlocutorType = async (
        interLocutorId: string,
        addNew = true,
        interlocutoType?: InterlocutorType,
    ) => {
        createOrLoadConversationInternal(interLocutorId, interlocutoType, true);
    };

    return {
        createOrLoadConversation,
        createOrLoadConversationByInterlocutorType,
        callSendMessage,
        setCallSendMessage,
    };
};

export default useCreateConversation;
