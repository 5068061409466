import './ConversationSelector.css';
import { useState, useEffect, useRef, useContext, SetStateAction, Dispatch } from 'react';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { formatToRelativeDate } from '../../common/messageInfoFormat';
import UnavailableAvatar from '../UnavailableAvatar/UnavailableAvatar';
import { useTranslation } from 'react-i18next';
import { fetchAvailabilityData } from '../../common/Availability';
import { ConversationContext } from '../../Contexts/conversationIdContext';
import { useNavigate } from 'react-router-dom';

import NotificationMessage from '../NotificationMessage/NotificationMessage';
import { getAvatarImagePath } from '../../common/avatarPicture';
import {
    hasNewMessageNotioficationInConversation,
    hasReadSpecificMessageByUserNotification,
    isStatusChanged,
} from '../../common/notificationsUtil';
import { v4 as uuidv4 } from 'uuid';
import { NotificationsContext } from '../../Contexts/notificationsContext';
import { InterlocutorType } from '../../common/InterlocutorType';

import {
    createUsersApi,
    ApiMsConversations,
    createConversationsApi,
    createMessagesApi,
    buildLogsAndPostToIndexedDB,
    settings,
    useAuth,
} from 'idside-core';
import { SvgIcon } from '@progress/kendo-react-common';
import { trashIcon } from '@progress/kendo-svg-icons';

interface ConversationRecipients {
    id: string;
    isAlertActive: boolean;
    masked?: boolean;
}

interface ConversationSelectorProps {
    received: boolean;
    id: string; //Id Conversation
    name: string;
    recipients: Array<ConversationRecipients>;
    //onSelected: (arg: string) => void;
    onSearchSelected: string;
    setConversations: Dispatch<SetStateAction<ApiMsConversations.ConversationResponse[]>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    isActive: boolean;
    updateActiveConversation: () => void;
    allMessagesReaded: boolean;
}

// .cdt(fond bleuté lors de la sélection du message/onhover afficher poubelle) => .cg-chat / .cdt-bas+.cg-temps(font gras lors de réception de message + rouge)
// eslint-disable-next-line max-lines-per-function
const ConversationSelector = ({
    received,
    id,
    name, //name is not defined in ConversationSelectorList but is listed in the params ?
    recipients,
    //onSelected,
    onSearchSelected,
    setConversations,
    setLoading,
    isActive,
    updateActiveConversation,
    allMessagesReaded,
}: ConversationSelectorProps) => {
    const [t] = useTranslation('common');
    const navigate = useNavigate();
    const { firebaseNotifications, orgID, accountId, currentUserId, setError, setErrorContent } =
        useContext(GlobalInfoContext);
    const { setCurrentConversationId, setIsGroup, setClickedConversation } = useContext(ConversationContext);
    const conversationSelectorHtmlRef = useRef<HTMLInputElement>(null); //TODO: Refactor this to use state instead
    const [userName, setUserName] = useState<string>();
    const [photoURL, setPhotoURL] = useState<string>(getAvatarImagePath(undefined, undefined));
    const [lastMessageText, setLastMessageText] = useState<string>('');
    const [lastMessageDate, setLastMessageDate] = useState<string>('');
    const [showUnavailable, setShowUnavailable] = useState<boolean>();
    const [availabilityDate, setAvailabilityDate] = useState<any>();
    const [isDeleted, setIsDeleted] = useState<boolean>();
    const [interlocutorUserId] = useState<string>('');
    const [toogleDeleteConversationPopup, setToogleDeleteConversationPopup] = useState<boolean>(false);
    const { getToken, authInstance } = useAuth();
    const [showEditConversation, isShowEditConversation] = useState<boolean>(true);
    const kcToken: string = getToken() ? getToken()! : '';
    const { sethasReadNewMessage } = useContext(NotificationsContext);
    const [lastMessage, setLastMessage] = useState<ApiMsConversations.MessageResponse>();
    const [isNew, setIsNew] = useState<boolean>(false);

    const isBasicConversation = (): boolean => {
        return !name
    };

    //Fetch availability once application has loaded
    useEffect(() => {
        //Update status only if it a basic conversation and not a group conversation   
        if (isBasicConversation()) {
            fetchAvailabilityData(
                recipients,
                orgID,
                kcToken,
                setError,
                setErrorContent,
                setShowUnavailable,
                setAvailabilityDate,
                setIsDeleted,
                currentUserId,
                setPhotoURL,
                authInstance,
            );
        }
    }, [currentUserId]);

    useEffect(() => 
    {
        //Update status only if it a basic conversation and not a group conversation   
        if (isBasicConversation()) {
            if (isStatusChanged(firebaseNotifications, recipients)) {
                fetchAvailabilityData(
                    recipients,
                    orgID,
                    kcToken,
                    setError,
                    setErrorContent,
                    setShowUnavailable,
                    setAvailabilityDate,
                    setIsDeleted,
                    currentUserId,
                    setPhotoURL,
                    authInstance,
                );
            }
        }
    }, [firebaseNotifications]);

    //TODO: Manage firebase
    useEffect(() => {
        if (received) {
            if (conversationSelectorHtmlRef.current !== null) {
                //axios calling the services
                //TODO, verify if the class received is used somewhere in particular
                conversationSelectorHtmlRef.current.classList.add('received');
            }
        }
    }, [received]);

    useEffect(() => {
        const start = new Date().getTime();
        const getReceiveUsername = async () => {
            let interlocutorId: string = '';
            if (getToken() && recipients) {
                try {
                    recipients.forEach((recipient: ConversationRecipients) => {
                        if (recipient.id !== currentUserId) {
                            interlocutorId = recipient.id;
                        }
                    });

                    if (interlocutorId !== '') {
                        const usersApi = await createUsersApi(authInstance!, orgID);
                        const response = await usersApi.getUserSummaryById(
                            interlocutorId,
                            orgID,
                        );
                        if (response.data && response.data.name) {
                            setUserName(response.data.name);
                        }

                        setPhotoURL(getAvatarImagePath(response.data.type, response.data.isDeleted));
                    }
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - start,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        currentUserId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    console.error('Error get Receive user Conversation selector', err);
                    setErrorContent(err);
                    setError(true);
                }
            }
        };

        !name && getReceiveUsername();

        //set image
        if (name) {
            //If there is a conversation name, then we alreayd know it is a Group Discussion
            setPhotoURL(getAvatarImagePath(InterlocutorType.GroupDiscussion, undefined));
            isShowEditConversation(false);
        }
    }, [interlocutorUserId, currentUserId, name, setErrorContent, setError, orgID]);

    // eslint-disable-next-line max-lines-per-function
    const getLastMessagePreview = async () => {
        const start = new Date().getTime();
        if (id) {
            if (getToken())
                try {
                    const conversationsApi = await createConversationsApi(authInstance!);
                    const { data: allMessagesOfConversation } = await conversationsApi.getAllCurrentUserMessagesOfConversationV3(
                        id, //id
                        currentUserId, //currentUserId,
                        orgID,
                        false, //return document data
                        undefined, //isDecryptionActive
                        undefined, // befroe createdDate
                        undefined, //createdDate
                        1, //maxElement
                        undefined, //isPhotoIncluded
                    );

                    let mostRecentText: string = '';
                    let mostRecentDate: Date | undefined = undefined;
                    let hasLastMessage: boolean = false;
                    await Promise.all(
                        //TODO: We could simplify this since the function will only return  one message or none so to need to
                        //iterate thru the list and use the lastest (this is remaining code from before the get message began
                        //supporting pagination and having a max element)
                        allMessagesOfConversation.map(async (message: ApiMsConversations.MessageResponse) => {
                            const currentMessageCreatedDate: Date | undefined =
                                message.createdDate !== undefined ? new Date(message.createdDate) : undefined;
                            setLastMessage(message);
                            if (currentMessageCreatedDate) {
                                if (!hasLastMessage || !mostRecentDate || currentMessageCreatedDate > mostRecentDate) {
                                    mostRecentDate = currentMessageCreatedDate;
                                    mostRecentText = message.messageData!;
                                    hasLastMessage = true;
                                }
                                if (hasLastMessage) {
                                    const foundNewMessageInfo = message.recipients!.find(
                                        (recipient: ApiMsConversations.MessageRecipientResponse) => {
                                            return recipient.id === currentUserId && recipient.readDate === undefined;
                                        },
                                    );
                                    const hasReceivedNewMessage = message.recipients!.find(
                                        (recipient: ApiMsConversations.MessageRecipientResponse) => {
                                            return recipient.id === currentUserId && !recipient.receivedDate;
                                        },
                                    );

                                    if (hasReceivedNewMessage) {
                                        let receivedData: ApiMsConversations.MessageUpdateRequest;
                                        receivedData = {
                                            recipientId: currentUserId,
                                            sentMessage: true,
                                        };
                                        const messagesApi = await createMessagesApi(authInstance!); 
                                        await messagesApi.modifyRecipientMessage(
                                            orgID,
                                            message.id!,
                                            currentUserId,
                                            receivedData,
                                            true,
                                        );
                                    }

                                    if (message.authorId === currentUserId) {
                                        setIsNew(false);
                                    }
                                    if (foundNewMessageInfo) {
                                        //TODO enlever tout ce qui a attrait à conversationSelectorHtmlRef en ajout ou enlever une class,
                                        //Vaudrait mieux utiliser un state ie: si je trouve un recipient qui a pas de readDate je rajoute la class sans passer par classList.add/remove
                                        if (message.authorId !== currentUserId) {
                                            setIsNew(true);
                                            return true;
                                        }
                                    }
                                }
                            }
                        }),
                    );

                    if (hasLastMessage) {
                        setLastMessageText(mostRecentText);
                        const formattedDate: string = formatToRelativeDate(mostRecentDate, false, false);
                        setLastMessageDate(formattedDate);
                    } else {
                        //console.log('formatteddate: No message');
                    }
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - start,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        currentUserId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    console.log('Error obtaining messages "', id, '" : ', err);
                    setErrorContent(err);
                    setError(true);
                }
        }
    };

    useEffect(() => {
        getLastMessagePreview();
    }, [currentUserId, id, orgID, setError, setErrorContent]);

    useEffect(() => {
        if (hasNewMessageNotioficationInConversation(firebaseNotifications, id)) {
            getLastMessagePreview();
        }
        updateMessageRead();
    }, [firebaseNotifications]);

    const handleChange = (): void => {
        if (conversationSelectorHtmlRef.current !== null) {
            //Verify if the class hover is used
            conversationSelectorHtmlRef.current.classList.add('hover');
        }
    };

    const handleChangeOut = (): void => {
        if (conversationSelectorHtmlRef.current !== null) {
            //Verify if the class hover is used
            conversationSelectorHtmlRef.current.classList.remove('hover');
        }
    };

    const onLoadActive = (): void => {
        updateActiveConversation();
        navigate(`/chat/${orgID}/${id}`);
        if (conversationSelectorHtmlRef.current !== null) {
            //Verify if the class hover is used
            conversationSelectorHtmlRef.current.classList.add('hover');
            setCurrentConversationId(id);
            setClickedConversation({ conversationId: id, clickedEventId: '' });
            name !== undefined ? setIsGroup(true) : setIsGroup(false);
        }
    };

    const setActive = (): void => {
        updateActiveConversation();
        navigate(`/chat/${orgID}/${id}`);
        if (conversationSelectorHtmlRef.current !== null) {
            if (isNew) {
                setIsNew(false);
            }
            //Verify if the class hover is used
            conversationSelectorHtmlRef.current.classList.add('hover');
            conversationSelectorHtmlRef.current.scrollIntoView({ behavior: 'smooth' });

            setCurrentConversationId(id);
            setClickedConversation({ conversationId: id, clickedEventId: uuidv4() });
            name !== undefined ? setIsGroup(true) : setIsGroup(false);
        }
    };

    useEffect(() => {
        if (allMessagesReaded && conversationSelectorHtmlRef.current !== null) {
            setIsNew(false);
        }
    }, [allMessagesReaded]);

    const updateMessageRead = () => {
        if (hasReadSpecificMessageByUserNotification(firebaseNotifications, currentUserId, lastMessage)) {
            setIsNew(false);
            sethasReadNewMessage(true);
        }
    };

    const showSearchSelected = (): void => {
        if (id === onSearchSelected) {
            onLoadActive();
        }
    };

    const updateConversation = async (conversationIdToUpdate: string) => {
        const start = new Date().getTime();
        try {
            const conversationsEditApi = await createConversationsApi(authInstance!, orgID);
            await conversationsEditApi.editConversationV2(
                conversationIdToUpdate,
                {
                    editedRecipients: [
                        {
                            id: accountId,
                            masked: true,
                        },
                    ],
                },
                {
                    headers: {
                        currentUserId: currentUserId,
                    },
                },
            );
            setCurrentConversationId('');
            navigate('/chat/');
            setLoading(true);
            
            /*TODO when back-end fixes bug with getConversationsByRecipientId remove putConversationApi and replace with. 
            conversationApi*/
            const conversationsRecipientsApi = await createConversationsApi(authInstance!, orgID);
            const { data } = await conversationsRecipientsApi.getConversationsByRecipientId(
                orgID,
                accountId,
                true,
            );
            setConversations(data);
        } catch (err: any) {
            await buildLogsAndPostToIndexedDB(
                err.message,
                err.stack,
                new Date().getTime() - start,
                settings.appId,
                window.location.origin,
                err.config.headers.trackingId,
                err.config[`axios-retry`].retries,
                authInstance?.sessionId!,
                currentUserId,
                err.config.headers.deviceId,
                err.config.headers.organisationId,
                err.config.headers.provider,
                settings.dbName,
                settings.storeIdTexto,
            );
            console.log(err);
        } finally {
            setToogleDeleteConversationPopup(false);
            setLoading(false);
        }
    };

    const onConfirmUpdateConversationPopup = async () => {
        await updateConversation(id);
        sethasReadNewMessage(true);
    };

    const onCloseDeleteConversationPopup = () => {
        setToogleDeleteConversationPopup(false);
    };
    const onOpenDeleteConversationPopup = () => {
        setToogleDeleteConversationPopup(true);
    };

    return (
        <>
            {toogleDeleteConversationPopup && (
                <NotificationMessage
                    onClose={onCloseDeleteConversationPopup}
                    onConfirmYes={onConfirmUpdateConversationPopup}
                    title={t('confirmation', { ns: 'validation' })}
                    textContent={t('confirmDeleteConversation', { ns: 'validation' })}
                />
            )}
            <div
                className={`cdt ${isActive ? 'selected ' : ''}${isNew ? 'important ' : ''}`}
                data-testid="cdt"
                ref={conversationSelectorHtmlRef}
                onMouseEnter={handleChange}
                onMouseLeave={handleChangeOut}
                onClick={setActive}
                onLoad={showSearchSelected}>
                <div className="cg-chat">
                    <div className={showUnavailable && !name ? 'avatar-container' : ''}>
                        {showUnavailable && !name && <UnavailableAvatar />}
                        <img
                            data-testid="cg-chat-perso-conversation-selector"
                            src={photoURL}
                            className="cg-chat-perso"
                            alt=" "
                        />
                    </div>
                    <div className="chat-info-container">
                        <div className="cg-top-info">
                            <div className="cg-nom">{name ? name : userName}</div>
                            <div className="cg-temps">{lastMessageDate}</div>
                        </div>
                        <div className="cdt-bas">
                            <div
                                className={
                                    !showUnavailable || isDeleted ? 'message-recent' : 'message-recent-non-available'
                                }>
                                {(showUnavailable && !name) || isDeleted ? (
                                    <div className="not-available" data-testid="not-available">
                                        {isDeleted ? (
                                            <i data-testid="deleted-status-text-left">{t('userDeleted')}</i>
                                        ) : (
                                            <>
                                                {t('availability')}
                                                <div style={{ whiteSpace: 'pre' }}>{availabilityDate}</div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <p data-testid="last-message">{lastMessageText}</p>
                                )}
                            </div>
                            {showEditConversation && accountId === currentUserId ? (
                                <SvgIcon
                                    className="selec-icon-small"
                                    onClick={onOpenDeleteConversationPopup}
                                    icon={trashIcon}></SvgIcon>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConversationSelector;
