/* eslint-disable max-lines-per-function */
import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import './organizationSelector.css';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../component/PageContainer/PageContainer';
import QcLogoWhite from '../../assets/Logo-Quebec-white.png';
import QcLogoBlack from '../../assets/Logo-Quebec.png';
import LogoIdTexto from '../../assets/images/logoIdTexto.svg';
import {
    createUsersApi,
    ApiMsOrganisations,
    createOrganisationsApi,
    buildLogsAndPostToIndexedDB,
    settings,
    useAuth,
} from 'idside-core';

const OrganizationSelector = () => {
    const [organisationList, setOrganisationList] = useState<ApiMsOrganisations.OrganisationResponse[]>([]);
    const [organisationIds, setOrganisationIds] = useState<string[]>();
    const { accountId, setOrgID, setSecurityActive, isOrgSelected, organisationDefault } =
        useContext(GlobalInfoContext);
    const [t] = useTranslation();
    const [load, isLoad] = useState<boolean>(false);
    const [isSpinnerLoading, setIsSpinnerLoading] = useState(true);
    const selectedRef = useRef<any>({});
    const { getToken, authInstance } = useAuth();

    const changeLogoIn = (index: number) => {
        selectedRef.current[index].firstChild.src = QcLogoWhite;
    };

    const changeLogoOut = (index: number) => {
        selectedRef.current[index].firstChild.src = QcLogoBlack;
    };

    useEffect(() => {
        const fetchUserOrgs = async () => {
            const startFetchUserOrgs = new Date().getTime();
            if (accountId) {
                try {
                    const usersApi = await createUsersApi(
                        authInstance!,
                        organisationDefault!,
                    )
                    const response = await usersApi.getUserOrganisationIdsByToken(getToken() ?? '');
                    setOrganisationIds(response.data.organisationIds);
                    isLoad(true);
                    setIsSpinnerLoading(true);
                } catch (err: any) {
                    await buildLogsAndPostToIndexedDB(
                        err.message,
                        err.stack,
                        new Date().getTime() - startFetchUserOrgs,
                        settings.appId,
                        window.location.origin,
                        err.config.headers.trackingId,
                        err.config[`axios-retry`].retries,
                        authInstance?.sessionId!,
                        accountId,
                        err.config.headers.deviceId,
                        err.config.headers.organisationId,
                        err.config.headers.provider,
                        settings.dbName,
                        settings.storeIdTexto,
                    );
                    console.log('error organisations', err);
                }
            }
        };

        fetchUserOrgs();
    }, [accountId, authInstance, getToken, organisationDefault]);

    useEffect(() => {
        const fetchOrgName = async () => {
            const startFetchOrgName = new Date().getTime();
            try {
                if (getToken() && load) {
                    const organisations: ApiMsOrganisations.OrganisationResponse[] = [];
                    if (organisationIds) {
                        for (const element of organisationIds) {
                            const orgApi = await createOrganisationsApi(
                                element, authInstance,
                            )
                            const result = await orgApi.getOrganisationById(
                                element,
                            );
                            organisations.push(result.data);
                        }
                        setOrganisationList((organisationName) => [...organisationName, ...organisations]);
                        setIsSpinnerLoading(false);
                    }
                }
            } catch (err: any) {
                await buildLogsAndPostToIndexedDB(
                    err.message,
                    err.stack,
                    new Date().getTime() - startFetchOrgName,
                    settings.appId,
                    window.location.origin,
                    err.config.headers.trackingId,
                    err.config[`axios-retry`].retries,
                    authInstance?.sessionId!,
                    accountId,
                    err.config.headers.deviceId,
                    err.config.headers.organisationId,
                    err.config.headers.provider,
                    settings.dbName,
                    settings.storeIdTexto,
                );
                console.log('error organisations', err);
            }
        };
        if (organisationList.length === 0) fetchOrgName();
    }, [accountId, authInstance, getToken, load, organisationDefault, organisationIds, organisationList]);

    useEffect(() => {
        if (organisationIds?.length === 1) {
            sessionStorage.secActive = true;
            setOrgID(organisationIds[0]);
            setSecurityActive(sessionStorage.secActive);
            isOrgSelected(true);
        }
    }, [isOrgSelected, organisationIds, setOrgID, setSecurityActive]);

    const selectOrganization = (selectedOrgId: string) => {
        setOrgID(selectedOrgId!);
        sessionStorage.orgID = selectedOrgId;
        isOrgSelected(true);
    };

    return (
        <>
            <PageContainer loading={isSpinnerLoading}>
                <div>
                    <div
                        className="container-fluid bg-primary height-90 big-org-container"
                        data-testid="organisation-selector">
                        <div className="row align-items-center container-organization">
                            <div className="col org-items-container">
                                <div className="d-flex justify-content-center">
                                    <img className="logo-org" data-testid="logo-org" src={LogoIdTexto} alt="logo" />
                                </div>
                                <div
                                    className="d-flex justify-content-center align-middle"
                                    data-testid="organization-container">
                                    <form className="organization-container" data-testid="organization-form">
                                        <div className="choose-organization">
                                            {/* TODO: translate */}
                                            <div className="choose-org-title" data-testid="choose-org-title">
                                                {t('login')}
                                            </div>
                                            <div className="choice-container">
                                                {organisationList.map(
                                                    (
                                                        organization: ApiMsOrganisations.OrganisationResponse,
                                                        i: number,
                                                    ) => {
                                                        return (
                                                            <Link
                                                                to=""
                                                                style={{ textDecoration: 'none' }}
                                                                key={organization.id}
                                                                data-testid={`org-choice-${i}`}>
                                                                <div
                                                                    onMouseOver={() => changeLogoIn(i)}
                                                                    onMouseOut={() => changeLogoOut(i)}
                                                                    className="org-choice"
                                                                    ref={(element) =>
                                                                        (selectedRef.current[i] = element)
                                                                    }
                                                                    onClick={() =>
                                                                        selectOrganization(organization.id!)
                                                                    }>
                                                                    <img
                                                                        className="logo-list"
                                                                        src={QcLogoBlack}
                                                                        alt="logo"
                                                                    />
                                                                    {organization.name}
                                                                </div>
                                                            </Link>
                                                        );
                                                    },
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </>
    );
};

export default OrganizationSelector;
