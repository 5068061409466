import { useContext, useEffect, useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../../component/LoadingSpinner/LoadingSpinner';
import { GlobalInfoContext } from '../../Contexts/globalInfoContext';
import { NotificationsContext } from '../../Contexts/notificationsContext';
import { NavbarIdtextoUIContainer } from 'idside-ui';
import { getDeviceToken, useAuth } from 'idside-core';
import { deleteNotificationDevice } from '../../common/notificationsUtil';
interface ILogin {
    path: string;
}

export default function StandardLogin({ path }: ILogin) {
    const [getUrl, setUrl] = useState(String);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { lastVirtualTeam, orgID, orgSelected, setSecurityActive, currentUserId } = useContext(GlobalInfoContext);
    const { hasUnreadMessage } = useContext(NotificationsContext);
    const auth = useAuth();
    const { isAuthenticated, doLoginUrl, doLogout, getToken, authInstance } = auth;
    useEffect(() => {
        if (authInstance)
            if (isAuthenticated) {
                navigate(path);
            } else {
                const url = doLoginUrl({ redirectUri: window.location.origin });
                setUrl(url ?? '');
            }
    }, [doLoginUrl, isAuthenticated, authInstance, navigate, path]);

    useEffect(() => {
        sessionStorage.removeItem('currentUserName');
        sessionStorage.removeItem('alias');
        sessionStorage.removeItem('currentUserId');
    }, []);

    // window.onload = () => {
    //     let iframe = document.getElementById('iframe');
    //     document.getElementById('iframe')?.focus();
    //     if (iframe?.isConnected === true) {
    //         iframe.autofocus = true;
    //     }
    // };

    //document?.getElementById('iframe')?.focus();
    //TODO replace localhost to current url

    useEffect(() => {
        if (getToken()) {
            if (window.self !== window.parent) {
                if (window.parent != null) {
                    window.parent.document.body.style.display = 'none';

                    window.parent.location = window.location.origin;
                }
            }
        }
    }, [getToken]);
    const logout = async () => {
        sessionStorage.removeItem('accountId');
        sessionStorage.removeItem('impersonatorName');
        sessionStorage.removeItem('orgID');
        sessionStorage.removeItem('secActive');
        setSecurityActive(false);

        await deleteNotificationDevice(getDeviceToken(), orgID, currentUserId, authInstance);

        doLogout({ redirectUri: window.location.origin });
    };

    return (
        <>
            {!isAuthenticated && (
                <NavbarIdtextoUIContainer
                    authInstance={authInstance!}
                    currentOrganisationId={orgID}
                    isLoggedIn={isAuthenticated as boolean} // todo change here please
                    organisationConfigured={orgSelected}
                    lastVirtualTeam={lastVirtualTeam}
                    hasUnreadMessage={hasUnreadMessage}
                    showBackButton={false}
                    logout={logout}
                    authContext={auth}
                />
            )}
            <div className="container-keycloak">
                {(isLoading || !authInstance) && (
                    <div className="centerizedLoader">
                        <LoadingSpinner />
                    </div>
                )}
                <iframe onLoad={() => setIsLoading(false)} title="connect" id="iframe" src={getUrl}></iframe>
            </div>
        </>
    );
}
